<template>
  <div
    id="contract-overview"
    class="contract-overview"
    :class="{
      'smaller-size': $cr.breakpoint.mdAndDown,
    }"
  >
    <v-layout justify-space-between full-width>
      <v-flex class="contract-overview-column" style="flex-shrink: 1">
        <div>
          <strong>Contract Status:</strong>
          <span>{{ contractStatusString }}</span>
        </div>
        <div v-if="contract.customer.firstName && contract.customer.lastName">
          <strong>Customer Name:</strong>
          <span>
            {{ `${contract.customer.firstName} ${contract.customer.lastName}` }}
          </span>
        </div>
        <div v-if="contract.customer.phone">
          <strong>Customer Phone:</strong>
          <span>{{ formattedPhoneNumber }}</span>
        </div>
        <div v-if="contract.customer.email">
          <strong>Customer Email:</strong>
          <span>
            <a
              :id="`${contract.id}-contract-overview-mail-to-customer-email`"
              :href="`mailto:${contract.customer.email}`"
              target="_blank"
            >
              {{ contract.customer.email }}
            </a>
          </span>
        </div>
        <div v-if="contract.customer.customerAccount">
          <strong>Customer Account:</strong>
          <span>
            {{ contract.customer.customerAccount.name }}
          </span>
        </div>
      </v-flex>

      <v-flex class="contract-overview-column" style="flex-shrink: 1.5">
        <div>
          <strong>Billing Frequency:</strong>
          <span>{{ contract.contractBillingFrequency.label }}</span>
        </div>
        <div>
          <strong>Billing Method:</strong>
          <span>{{ contract.contractBillingMethod.label }}</span>
        </div>
        <div>
          <strong>Type of Payment:</strong>
          <span>{{ contract.contractPaymentType.label }}</span>
        </div>
        <div>
          <strong>Payment Terms:</strong>
          <span>{{ contract.contractPaymentTermsDays || '--' }}</span>
        </div>
        <div>
          <strong>Method of Payment:</strong>
          <span>{{ paymentMethodsText }}</span>
        </div>
        <div v-if="contract.companyTerm">
          <strong>Acceptance Terms:</strong>
          <span style="max-width: 50ch; vertical-align: top">
            {{ contract.companyTerm.label }}
          </span>
        </div>
      </v-flex>

      <v-flex class="contract-overview-column" style="flex-shrink: 1.5">
        <div>
          <strong>Contract Total:</strong>
          <span>{{ contractTotalAmount }}</span>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <CRIcon v-bind="attrs" class="pricing-tooltip-icon" v-on="on">
                info
              </CRIcon>
            </template>
            <span>
              The projected total of all trips, regardless of conversion status
            </span>
          </v-tooltip>
        </div>
        <div>
          <strong>Contract Balance:</strong>
          <span>{{ contractBalanceAmount }}</span>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <CRIcon v-bind="attrs" class="pricing-tooltip-icon" v-on="on">
                info
              </CRIcon>
            </template>
            <span>Sum of all charges, regardless of conversion status</span>
          </v-tooltip>
        </div>
        <div>
          <strong>Converted Total:</strong>
          <span>{{ convertedTotalAmount }}</span>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <CRIcon v-bind="attrs" class="pricing-tooltip-icon" v-on="on">
                info
              </CRIcon>
            </template>
            <span>Sum of the cost of all reservations</span>
          </v-tooltip>
        </div>
        <div>
          <strong>Converted Balance:</strong>
          <span>{{ convertedBalanceAmount }}</span>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <CRIcon v-bind="attrs" class="pricing-tooltip-icon" v-on="on">
                info
              </CRIcon>
            </template>
            <span>Sum of the cost of all reservations balances</span>
          </v-tooltip>
        </div>
        <div>
          <strong>Paid to Date:</strong>
          <span>{{ paidToDateAmount }}</span>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <CRIcon v-bind="attrs" class="pricing-tooltip-icon" v-on="on">
                info
              </CRIcon>
            </template>
            <span>Total amount paid</span>
          </v-tooltip>
        </div>
      </v-flex>
    </v-layout>

    <v-layout id="contract-overview-trips" class="contract-overview-trips">
      <v-flex>
        Booked On
        <br />
        <strong>{{ bookedOnDisplayText || '-' }}</strong>
      </v-flex>
      <v-flex>
        Start Date
        <br />
        <strong>{{ startDateDisplayText }}</strong>
      </v-flex>
      <v-flex>
        End Date
        <br />
        <strong>{{ endDateDisplayText }}</strong>
      </v-flex>
      <v-flex>
        Total Quotes
        <br />
        <strong>{{ contract.quoteCount || 0 }}</strong>
      </v-flex>
      <v-flex>
        Total Reservations
        <br />
        <strong>{{ contract.reservationCount || 0 }}</strong>
      </v-flex>
      <v-flex>
        Total Referrals
        <br />
        <strong>{{ totalReferrals || 0 }}</strong>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/currency'
import { metersToMilesString } from '@/utils/distance'
import { secondsToDaysAndHoursString } from '@/utils/time'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    contract: { type: Object, default: null },
    calendarDays: { type: Number, default: null },
    checkoutName: { type: String, default: '' },
  },
  data() {
    return {
      currencyFilter,
      metersToMilesString,
      secondsToDaysAndHoursString,
      referralStatusTypeMap: {
        offered: 'Offered',
        accepted: 'Accepted',
        rejected: 'Rejected',
        not_offered: 'Not Offered',
        fully_offered: 'Fully Offered',
        fully_accepted: 'Fully Accepted',
        fully_confirmed: 'Fully Confirmed',
        confirmed: 'Confirmed',
      },
      paymentStatusTypeMap: {
        not_paid: 'Not Paid',
        partially_paid: 'Partially Paid',
        fully_paid: 'Fully Paid',
      },
    }
  },
  computed: {
    ...authComputed,
    contractStatusString() {
      const openString = this.contract.contractIsOpen ? 'Open' : 'Closed'
      const signedString = this.contract.contractIsSigned
        ? 'Signed'
        : 'Unsigned'
      return `${signedString} and ${openString}`
    },
    formattedPhoneNumber() {
      let cleaned = ('' + this.contract.customer.phone).replace(/\D/g, '')
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    },
    convertedTotalAmount() {
      return currencyFilter(this.contract.convertedTotalAmount)
    },
    convertedBalanceAmount() {
      return currencyFilter(this.contract.convertedBalanceAmount)
    },
    contractTotalAmount() {
      return currencyFilter(this.contract.contractTotalAmount)
    },
    contractBalanceAmount() {
      return currencyFilter(
        this.contract.contractTotalAmount -
          (this.contract.convertedTotalAmount -
            this.contract.convertedBalanceAmount)
      )
    },
    paidToDateAmount() {
      return currencyFilter(
        this.contract.convertedTotalAmount -
          this.contract.convertedBalanceAmount
      )
    },
    totalReferrals() {
      let totalReferrals = 0
      this.contract.referralAggregates.forEach((referral) => {
        totalReferrals += referral.totalTrips
      })
      return totalReferrals
    },
    paymentMethodsText() {
      let paymentMethodsString = ''
      this.contract.contractPaymentMethods.forEach((paymentMethod) => {
        paymentMethodsString += `${paymentMethod.label}, `
      })
      return paymentMethodsString.substr(0, paymentMethodsString.length - 2)
    },
    bookedOnDisplayText() {
      if (this.contract.contractBookedOn) {
        return DateTime.fromISO(this.contract.contractBookedOn, {
          zone: this.currentUser.company.address.timeZone,
        }).toLocaleString(DateTime.DATETIME_SHORT)
      }
      return null
    },
    startDateDisplayText() {
      return DateTime.fromISO(this.contract.contractStartDate, {
        zone: this.currentUser.company.address.timeZone,
      }).toLocaleString(DateTime.DATE_SHORT)
    },
    endDateDisplayText() {
      return DateTime.fromISO(this.contract.contractEndDate, {
        zone: this.currentUser.company.address.timeZone,
      }).toLocaleString(DateTime.DATE_SHORT)
    },
  },
}
</script>

<style lang="scss" scoped>
.contract-overview {
  border: 1px solid $gray-light;

  &-column {
    padding: 10px;

    strong,
    span {
      display: inline-block;
    }

    strong {
      width: 140px;
      margin-right: 0.5em;
      text-align: right;
    }
  }

  &-trips {
    text-align: center;
    border-top: 1px solid $gray-light;

    > * {
      padding: 10px;

      &:not(:last-child) {
        border-right: 1px solid $gray-light;
      }
    }
  }

  &.smaller-size {
    span,
    strong,
    .referred-to-column {
      width: auto;
      text-align: left;
    }
  }
}

.vehicle-tooltip-list {
  padding: 0;
  list-style: none;
}

.vehicle-count-tooltip-activator {
  color: $primary;
}

.pricing-tooltip-icon {
  margin-left: 8px;
  cursor: pointer;
}
</style>
