<template>
  <div id="contract-referrals" class="contract-referrals">
    <v-layout column class="summary-container">
      <v-data-table
        :headers="headers"
        :items="contract.referralAggregates"
        :hide-actions="true"
        :align="'end'"
      >
        <template #items="referrals">
          <td align="start">
            <span class="pl-4">
              <v-tooltip top>
                <template #activator="{ on }">
                  <CRIcon
                    :color="
                      referralStatusIconColor(
                        referrals.item.aggregateReferralStatus
                      )
                    "
                    v-on="on"
                  >
                    affiliate_management
                  </CRIcon>
                </template>
                <span class="capitalize">
                  {{ referrals.item.aggregateReferralStatus }}
                </span>
              </v-tooltip>

              <v-menu bottom transition="slide-x-transition">
                <template #activator="more">
                  <v-icon
                    :id="`contract-referrals-${referrals.item.index}-table-row-kebab-menu`"
                    color="primary"
                    v-on="more.on"
                  >
                    more_vert
                  </v-icon>
                </template>
                <v-list>
                  <v-list-tile
                    @click="rejectReferrals(referrals.item.referralIds)"
                  >
                    <v-list-tile-title>
                      Reject All Offered Referrals
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    @click="acceptReferrals(referrals.item.referralIds)"
                  >
                    <v-list-tile-title>
                      Accept All Offered Referrals
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </span>
          </td>
          <td align="left">
            {{ referrals.item.managedId }}
          </td>
          <td align="left">
            {{ referrals.item.provider.name }}
            <br />
            {{
              `${referrals.item.provider.address.street1},
              ${referrals.item.provider.address.city},
              ${referrals.item.provider.address.state}
              ${referrals.item.provider.address.postalCode}`
            }}
            <br />
            <a :href="`tel:${referrals.item.provider.phone}`">
              {{ formatPhoneNumber(referrals.item.provider.phone) }}
            </a>
            <br />
            <a :href="`mailto:${referrals.item.provider.email}`">
              {{ referrals.item.provider.email }}
            </a>
          </td>
          <td align="center">
            {{ referrals.item.totalTrips }}
          </td>
          <td align="center">
            {{ referrals.item.requiredVehicles }}
          </td>
          <td align="center">
            {{ referrals.item.requiredDrivers }}
          </td>
          <td align="right">
            <span class="pr-4">
              {{ formatAmount(referrals.item.totalAmount) }}
            </span>
          </td>
        </template>
        <template #footer>
          <td colspan="3" align="start">Totals</td>
          <td colspan="1" align="center">{{ referralsTotals.trips }}</td>
          <td colspan="3" align="end">{{ referralsTotals.amount }}</td>
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import admin from '@/services/admin'
import { currencyFilter } from '@/utils/currency'

export default {
  props: {
    contract: { type: Object, required: true },
  },
  data() {
    return {
      headers: [
        { text: 'Referral', type: '', sortable: false },
        { text: 'ID', type: 'parentReferralId', sortable: false },
        { text: 'Company', type: '', sortable: false },
        { text: 'Trips', type: 'totalTrips', sortable: false, align: 'center' },
        {
          text: 'Vehicles',
          type: 'requiredVehicles',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Drivers',
          type: 'requiredDrivers',
          sortable: false,
          align: 'center',
        },
        { text: 'Amount', type: 'totalAmount', sortable: false, align: 'end' },
      ],
    }
  },
  computed: {
    referralsTotals() {
      let totals = { amount: 0, trips: 0 }
      this.contract.referralAggregates.forEach((referral) => {
        totals.amount += referral.totalAmount
        totals.trips += referral.totalTrips
      })
      totals.amount = currencyFilter(totals.amount)
      return totals
    },
  },
  watch: {},
  methods: {
    formatPhoneNumber(phoneNumber) {
      var cleaned = ('' + phoneNumber).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    },
    formatAmount(amount) {
      return currencyFilter(amount)
    },
    referralStatusIconColor(status) {
      if (['accepted', 'confirmed'].includes(status)) {
        return 'success'
      }
      if (['offered'].includes(status)) {
        return 'warning'
      }
      return 'error'
    },
    async rejectReferrals(reservationIds) {
      const payload = {
        reservationIds: reservationIds,
        contractId: this.contract.contractId,
      }
      try {
        await admin.rejectReferralsEnMasse(payload)
        this.$store.dispatch('app/showAlert', {
          color: 'success',
          message: 'Referrals successfully rejected',
        })
      } catch (e) {
        this.$store.dispatch('app/showAlert', {
          color: 'error',
          message: 'Error rejecting referrals',
        })
      }
      this.$emit('refresh')
    },
    async acceptReferrals(reservationIds) {
      const payload = {
        reservationIds: reservationIds,
        contractId: this.contract.contractId,
      }
      try {
        await admin.acceptReferralsEnMasse(payload)
        this.$store.dispatch('app/showAlert', {
          color: 'success',
          message: 'Referrals successfully accepted',
        })
      } catch (e) {
        this.$store.dispatch('app/showAlert', {
          color: 'error',
          message: 'Error accepting referrals',
        })
      }
      this.$emit('refresh')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-table__overflow {
  overflow-x: hidden;
}
.contract-referrals {
  padding: 0 8px 8px;

  .summary-container {
    .container {
      padding-left: 0;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

::v-deep table {
  margin-top: 0;
  border: 1px solid $gray-light;

  &.v-table thead tr:first-child th {
    padding: 17px 10px !important;
    font-size: 14px !important;
  }
  &.v-table thead tr:first-child th {
    padding: 17px 10px !important;
    font-size: 14px !important;
    &:first-child {
      padding-left: 24px !important;
    }
    &:last-child {
      padding-right: 24px !important;
    }
  }

  tfoot {
    height: 45px;
    font-size: 20px;
    line-height: 2;
    color: $white;
    text-align: center;
    background-color: $gray;
  }
}
</style>
