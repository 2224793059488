<template>
  <v-layout column>
    <v-layout class="padding-y-4" row>
      <v-flex xs4>
        <v-flex class="min-h-80 d-flex align-end" xs12>
          <p
            v-if="isReservationSelected"
            class="text-blue cursor-pointer margin-a-0"
            @click="clearSelectedReservation"
          >
            Back
          </p>
          <v-layout v-else class="align-center" row>
            <CRInput
              v-model="selectedDate"
              label="Filter by Date"
              type="date"
              class="no-grow w-140 margin-r-4"
              hide-details
            />
            <div
              v-for="status in statuses"
              :key="status.key"
              class="d-flex border-1 border-solid border-gray-light border-radius-pill padding-y-2 padding-x-4 margin-r-2 margin-t-3 cursor-pointer no-grow"
              :class="status.selected ? 'background-blue text-white' : ''"
              @click="status.selected = !status.selected"
            >
              <p class="margin-a-0">{{ status.name }}</p>
            </div>
          </v-layout>
        </v-flex>
        <div
          v-if="!isReservationSelected && !loading"
          class="max-h-600"
          style="overflow-y: auto"
        >
          <v-flex
            v-for="reservation in reservations"
            :key="reservation.reservationId"
            class="reservation-card padding-y-2 padding-x-5 border-1 border-solid border-radius-5 cursor-pointer margin-b-2 background-blue-pale"
            :class="{
              hover: hoverReservationId === reservation.reservationId,
            }"
            @click="selectedReservationId = reservation.reservationId"
            @mouseover="hoverReservationId = reservation.reservationId"
            @mouseleave="hoverReservationId = null"
            xs11
          >
            <v-layout class="align-center" row>
              <v-flex>
                <v-layout class="align-center" row>
                  <h3 class="font-weight-bold no-grow">
                    {{ reservation?.tripName }}
                  </h3>
                  <v-chip
                    v-if="reservation?.reservationStatus"
                    :color="
                      reservationStatusColor(reservation.reservationStatus)
                    "
                    label
                    disabled
                    class="margin-l-2 no-grow text-white"
                  >
                    {{ capitalize(reservation.reservationStatus) }}
                  </v-chip>
                </v-layout>
              </v-flex>
              <v-flex class="no-grow">
                <v-menu
                  v-model="menuOpen"
                  :id="`reservation-menu-${reservation.reservationId}`"
                  bottom
                  transition="slide-y-transition"
                >
                  <template #activator="{ on }">
                    <div
                      v-on="on"
                      @click.stop="
                        selectedMenuReservationId = reservation.reservationId
                      "
                    >
                      <CRIcon
                        height="24"
                        width="24"
                        view-box="0 0 24 24"
                        class="margin-l-2 cursor-pointer"
                      >
                        more_vert
                      </CRIcon>
                    </div>
                  </template>
                  <v-list
                    v-show="
                      selectedMenuReservationId === reservation.reservationId
                    "
                  >
                    <v-list-tile>
                      <v-list-tile-title>
                        <p
                          class="text-primary cursor-pointer margin-a-0"
                          @click="
                            openReservationTracking(reservation.reservationId)
                          "
                        >
                          View Reservation Tracking Map
                        </p>
                      </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile>
                      <v-list-tile-title>
                        <p
                          class="text-primary cursor-pointer margin-a-0"
                          @click="
                            openReservationDetail(reservation.reservationId)
                          "
                        >
                          View Reservation Detail
                        </p>
                      </v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs10>
                <p class="margin-a-0 text-gray-medium-dark">Pickup Location</p>
                <p class="margin-a-0 font-14 font-weight-bold">
                  {{ nextStop(reservation.stops)?.address?.title }}
                </p>
              </v-flex>
              <v-flex xs2>
                <p class="margin-a-0 text-gray-medium-dark">Time</p>
                <p class="margin-a-0 font-14 font-weight-bold">
                  {{ formatTime(nextStop(reservation.stops)) }}
                </p>
              </v-flex>
            </v-layout>
            <p
              v-if="reservation.referrals.length"
              class="margin-a-0 text-gray-medium-dark font-weight-bold padding-t-2"
            >
              Referrals
            </p>
            <v-layout
              v-for="referral in reservation.referrals"
              :key="referral.reservationId"
              class="padding-b-2"
              column
            >
              <v-flex xs12>
                <v-layout class="align-center" row>
                  <p class="margin-a-0 font-weight-bold font-16">
                    {{ referral.managedId }}
                  </p>
                  <p
                    v-if="
                      referral.requiredVehicles >
                      referral.tripAssignments.length
                    "
                    class="margin-a-0 text-primary font-weight-bold cursor-pointer padding-l-2"
                    @click="
                      openAssignmentsSidebar(
                        reservation.reservationId,
                        reservation.managedId,
                        referral.reservationId,
                        referral.companyId
                      )
                    "
                  >
                    + Add Assignment
                  </p>
                </v-layout>
              </v-flex>
              <v-layout
                v-for="tripAssignment in referral.tripAssignments"
                :key="tripAssignment.tripAssignmentId"
                class="padding-t-2"
                row
              >
                <v-flex xs6>
                  <v-layout
                    v-for="driver in tripAssignment.drivers"
                    :key="driver.tripAssignmentId"
                    class="cursor-pointer"
                    @click="
                      openAssignmentsSidebar(
                        reservation.reservationId,
                        reservation.managedId,
                        referral.reservationId,
                        referral.companyId
                      )
                    "
                    row
                  >
                    <CRIcon color="green" width="20" height="20">
                      drivers_circle
                    </CRIcon>
                    <p class="margin-a-0 font-weight-bold padding-l-2">
                      {{ driver.driverName }}
                    </p>
                  </v-layout>
                  <v-layout
                    v-if="tripAssignment.drivers.length === 0"
                    class="cursor-pointer"
                    @click="
                      openAssignmentsSidebar(
                        reservation.reservationId,
                        reservation.managedId,
                        referral.reservationId,
                        referral.companyId
                      )
                    "
                    row
                  >
                    <CRIcon color="grayMediumDark" width="20" height="20">
                      drivers_circle
                    </CRIcon>
                    <p
                      class="margin-a-0 font-weight-bold text-gray-medium-dark padding-l-2"
                    >
                      Unassigned
                    </p>
                  </v-layout>
                </v-flex>
                <v-flex
                  v-if="tripAssignment.vehicleLicensePlate"
                  @click="
                    openAssignmentsSidebar(
                      reservation.reservationId,
                      reservation.managedId,
                      referral.reservationId,
                      referral.companyId
                    )
                  "
                  xs6
                >
                  <v-layout class="align-center cursor-pointer" row>
                    <CRIcon color="green" width="20" height="20">
                      vehicles
                    </CRIcon>
                    <p class="margin-a-0 font-weight-bold padding-l-2">
                      {{ tripAssignment.vehicleName }}
                    </p>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <div
                          class="w-8 h-8 border-radius-round margin-l-1"
                          :class="
                            hasRecentTrackingData(tripAssignment.vehicleId)
                              ? 'background-green'
                              : 'background-red'
                          "
                          v-on="on"
                        ></div>
                      </template>
                      <div>
                        <span class="font-weight-bold">Vehicle type:</span>
                        {{
                          findVehicleTrackingDevice(tripAssignment.vehicleId)
                            ?.vehicleType || 'N/A'
                        }}
                      </div>
                      <div>
                        <span class="font-weight-bold">ELD type:</span>
                        {{
                          findVehicleTrackingDevice(tripAssignment.vehicleId)
                            ?.eldType || 'N/A'
                        }}
                      </div>
                      <div>
                        <span class="font-weight-bold">Last report time:</span>
                        {{
                          deviceReportTime(
                            findVehicleTrackingDevice(tripAssignment.vehicleId)
                          )
                        }}
                      </div>
                      <div v-if="!lastSyncOnly">
                        <span class="font-weight-bold">Device ID:</span>
                        {{
                          findVehicleTrackingDevice(tripAssignment.vehicleId)
                            ?.deviceId || 'N/A'
                        }}
                      </div>
                    </v-tooltip>
                  </v-layout>
                </v-flex>
                <v-flex
                  v-else
                  class="cursor-pointer"
                  @click="
                    openAssignmentsSidebar(
                      reservation.reservationId,
                      reservation.managedId,
                      referral.reservationId,
                      referral.companyId
                    )
                  "
                  xs6
                >
                  <v-layout class="align-center" row>
                    <CRIcon color="grayMediumDark" width="20" height="20">
                      vehicles
                    </CRIcon>
                    <p
                      class="margin-a-0 font-weight-bold text-gray-medium-dark padding-l-2"
                    >
                      Unassigned
                    </p>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-flex>
        </div>
        <ContractTrackingReservationDetail
          v-else-if="!loading"
          :reservation-id="selectedReservationId"
          :contract-id="contract.contractId"
          :vehicles="filteredVehicles"
        />
      </v-flex>
      <v-flex xs8>
        <CountdownTimer
          v-show="false"
          :time-limit="standardRefreshInterval"
          :size="timerSize"
          :style="$vuetify.breakpoint.xs ? 'margin-right: -14px' : ''"
          @countdown-finished="refresh"
        />
        <ContractTrackingMap
          id="map"
          ref="map"
          :filtered-vehicles="filteredVehicles"
          :stops="stops"
          :hover-reservation-id="hoverReservationId"
          :refresh-interval="standardRefreshInterval"
          @mouseover-vehicle="hoverReservationId = $event"
          @mouseout-vehicle="hoverReservationId = null"
          @click-vehicle="selectedReservationId = $event"
        />
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { capitalize } from '@/utils/string'
import { DateTime, Interval } from 'luxon'
import tracking from '@/services/tracking'
import { reservationById } from '@/services/reservations'
import ContractTrackingMap from '@/components/ContractTrackingMap.vue'
import CountdownTimer from '@/components/CountdownTimer.vue'
import {
  getReservationsForContractTracking,
  getReservationDetailForContractTracking,
} from '@/services/reservations'
import trackingDevices from '@/services/trackingDevices'
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
import ContractTrackingReservationDetail from './ContractTrackingReservationDetail.vue'
import { EventBus } from '@/utils/event-bus'
import drivers from '@/services/drivers'
import { getTripAssignmentsForReservation } from '@/services/reservations'
import { timeAgo } from '@/utils/time'
import { ReservationStatus } from '@/utils/enum'

const RECENT_TRACKING_MINUTES = 5

export default {
  components: {
    ContractTrackingMap,
    CountdownTimer,
    ContractTrackingReservationDetail,
  },
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedDate: DateTime.local().toFormat('yyyy-MM-dd'),
      statuses: [
        {
          name: 'Started',
          key: 'started',
          selected: false,
        },
        {
          name: 'Upcoming',
          key: 'upcoming',
          selected: false,
        },
        {
          name: 'Finished',
          key: 'finished',
          selected: false,
        },
      ],
      reservations: [],
      standardRefreshInterval: 10,
      lastVehiclePositions: [],
      hoverReservationId: null,
      selectedReservationId: null,
      loading: false,
      loadingAssignments: false,
      debounce: null,
      menuOpen: false,
      selectedMenuReservationId: null,
    }
  },
  computed: {
    ...authComputed,
    isReservationSelected() {
      return !!this.selectedReservationId
    },
    timerSize() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return 40
      }
      return 30
    },
    stops() {
      const activeStops = []
      for (const reservation of this.reservations.filter(
        (tr) => tr.reservationId === this.selectedReservationId
      )) {
        if (reservation.stops) {
          for (const [stopIndex, stop] of reservation.stops.entries()) {
            activeStops.push({
              ...stop,
              stopIndex,
              reservationId: reservation.reservationId,
            })
          }
        }
      }
      const itinerary = activeStops
        .filter(
          (stop, index, self) =>
            index ===
            self.findIndex(
              (s) =>
                s.address.lat === stop.address.lat &&
                s.address.lng === stop.address.lng &&
                s.reservationId === stop.reservationId
            )
        )
        .map((stop) => {
          return {
            address: stop.address,
            reservationId: stop.reservationId,
            pickupTimes: new Set(),
            dropoffTimes: new Set(),
            markerLabel: stop.stopIndex + 1,
          }
        })
      for (const stop of activeStops) {
        const itineraryItem = itinerary.find(
          (waypoint) =>
            waypoint.address.lat === stop.address.lat &&
            waypoint.address.lng === stop.address.lng
        )
        if (stop.pickupDatetime) {
          itineraryItem.pickupTimes.add(stop.pickupDatetime)
        }
        if (stop.dropoffDatetime) {
          itineraryItem.dropoffTimes.add(stop.dropoffDatetime)
        }
      }
      for (const stop of itinerary) {
        stop.pickupTimes = [...stop.pickupTimes]
        stop.dropoffTimes = [...stop.dropoffTimes]
      }
      return itinerary
    },
    selectedStatuses() {
      return this.statuses
        .filter((status) => status.selected)
        .map((status) => status.key)
    },
    filteredVehicles() {
      let filteredVehicles = []
      if (!this.lastVehiclePositions) {
        filteredVehicles = []
      } else {
        filteredVehicles = this.lastVehiclePositions
      }
      if (this.selectedReservationId) {
        filteredVehicles = filteredVehicles.filter(
          (fv) =>
            fv?.reservation?.parentReservationId === this.selectedReservationId
        )
      } else {
        filteredVehicles = filteredVehicles.filter((fv) =>
          this.reservations
            .map((r) => r.reservationId)
            .includes(fv?.reservation?.parentReservationId)
        )
      }
      return filteredVehicles
    },
  },
  watch: {
    selectedDate() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (!this.loading) {
        this.debounce = setTimeout(() => {
          this.getReservations()
          this.getTrackingForAllVehicles(true)
        }, 500)
        return
      }
      this.getReservations()
      this.getTrackingForAllVehicles(true)
    },
    selectedStatuses() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (!this.loading) {
        this.debounce = setTimeout(() => {
          this.getReservations()
        }, 500)
        return
      }
      this.getReservations()
      this.getTrackingForAllVehicles(true)
    },
  },
  async mounted() {
    await this.getReservations()
    await this.getTrackingForAllVehicles(true)
    EventBus.$on('global-table-view-refresh', async () => {
      this.$store.dispatch('app/closeDialog')
      this.clearSelectedReservation()
      await this.getReservations()
      await this.getTrackingForAllVehicles(true)
    })
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    capitalize,
    async getReservations() {
      const payload = {
        contractQuoteId: this.contract.contractId,
        date: DateTime.fromISO(this.selectedDate).toISO(),
        timeZone: this.currentUser.timeZone,
        status: this.selectedStatuses,
      }
      try {
        this.loading = true
        const reservationsData = await getReservationsForContractTracking(
          payload
        )

        this.reservations = reservationsData?.data.sort((a, b) => {
          const statusOrder = {
            [ReservationStatus.Started.toLowerCase()]: 1,
            [ReservationStatus.Upcoming.toLowerCase()]: 2,
            [ReservationStatus.Finished.toLowerCase()]: 3,
          }

          return (
            statusOrder[a.reservationStatus] - statusOrder[b.reservationStatus]
          )
        })
        this.loading = false
      } catch (e) {
        console.error(e)
        this.showAlert({
          type: 'error',
          message: 'Unable to load reservations.',
        })
        this.loading = false
      }
    },
    async getTrackingForAllVehicles(firstLoad = false) {
      this.loading = firstLoad
      let vehicles = []

      const response = await tracking.getTrackingByContractHash(
        this.contract.hash
      )

      vehicles = response.data.gpsData || []
      if (vehicles) {
        let contractName = null
        for (const vehicle of vehicles) {
          vehicle.lastTransmitted = vehicle.receivedDate
          vehicle.heading = this.computeHeading(vehicle)
          vehicle.direction = this.headingToDirection(vehicle.heading)
          vehicle.hover = false
          vehicle.reservationId = vehicle?.reservation?.managedId
          vehicle.contractName = null
          vehicle.tripName = vehicle?.reservation?.tripName
          vehicle.contractName = vehicle?.reservation?.contractName
        }
        this.contractName = contractName
      }

      const filteredVehiclesByVehicleId = vehicles.reduce((acc, cur) => {
        if (
          !acc[cur.vehicleId] ||
          DateTime.fromISO(cur.lastTransmitted) >
            DateTime.fromISO(acc[cur.vehicleId].lastTransmitted)
        ) {
          acc[cur.vehicleId] = cur
        }
        return acc
      }, {})
      this.lastVehiclePositions = Object.values(filteredVehiclesByVehicleId)
      this.loading = false
    },
    // TODO: Clean up the dispatch reservation sidebar and make a new endpoint that surfaces all the information needed so it does not have to
    // be called before-hand
    async openAssignmentsSidebar(reservationId, managedId, referralId, companyId) {
      this.loadingAssignments = true
      try {
        const component = () => import('./DispatchReservationSidebar.vue')
        const res = await reservationById(managedId)
        const reservation = res.data
        const referral = reservation.referredTo.find(
          (referral) => referral.reservationId === referralId
        )
        const driverData = await drivers.getAllDriversForCompany({
          page: 1,
          pageSize: -1,
          companyId: companyId,
        })
        const driverList = driverData?.data?.resultList
        const vehicleData = await this.$store.dispatch(
          'vehicles/getAllVehiclesForCompany',
          {
            page: 1,
            pageSize: -1,
            companyId: companyId,
          }
        )
        const vehicleList = vehicleData?.data?.resultList

        const allDrivers = driverList.map((driver) => ({
          ...driver,
          name: `${driver.firstName} ${driver.lastName}`,
        }))
        const allVehicles = vehicleList.map((vehicle) => ({
          ...vehicle,
          name: `${vehicle.vehicleName} ${vehicle.vehicleModel}`,
        }))
        this.loadingAssignments = false

        this.$store.dispatch('app/openSidebarDialog', {
          component,
          data: {
            title: 'Add Assignments',
            parentReservationId: reservationId,
            reservationId: referral.reservationId,
            managedId: referral.managedId,
            referral: referral,
            isAssignmentsOnly: true,
            vehicles: allVehicles,
            drivers: allDrivers,
            garages: [],
            companyId: companyId,
          },
          persistent: true,
        })
      } catch (e) {
        this.loadingAssignments = false
        console.error(e)
        this.showAlert({
          type: 'error',
          message: 'Unable to load reservation assignments.',
        })
      }
    },
    async openReservationTracking(reservationId) {
      const reservation = await this.$store.dispatch(
        'reservations/fetchReservationById',
        {
          reservationId: reservationId,
          companyId: this.currentUser?.companyId,
          viewRejectedReferrals: false,
          force: true,
        }
      )

      if (!reservation) {
        return
      }

      let referredProviders = reservation?.referredTo?.filter(
        (ref) => ref.referralStatus === 'accepted'
      )
      referredProviders = referredProviders?.map((provider) => {
        let computedName = provider.companyName
        if (
          referredProviders.filter((p) => p.companyId === provider.companyId)
            .length > 1
        ) {
          computedName += ` - ${provider.managedId}`
        }
        return { computedName, ...provider }
      })

      const currentProvider = reservation?.referredTo?.[0]
      let tripVehicleGroups = []
      if (currentProvider?.tripVehicleGroups) {
        tripVehicleGroups = currentProvider.tripVehicleGroups
      } else {
        tripVehicleGroups = reservation.tripVehicleGroups
      }

      const trackingStatusResult = await this.$store.dispatch(
        'reservations/getReservationTrackingStatuses',
        [reservationId]
      )
      const trackingReservationStatus =
        trackingStatusResult?.data?.trackingStatuses[0]?.reservationStatus
      const trackingAllocation =
        trackingStatusResult?.data?.trackingStatuses[0]?.trackingAllocation

      const itineraryItems = reservation.stops?.map((stop, index) => {
        const {
          stopId,
          address,
          notes,
          stopNotes,
          risks,
          flightInformation,
          tripVehicleGroupId,
        } = stop
        const newStop = {
          stopNumber: index + 1,
          stopId,
          address,
          timeZone: address.timeZone,
          notes,
          stopNotes,
          risks,
          flightInformation,
          tripVehicleGroupId,
        }
        if (stop.dropoffDatetime && stop.pickupDatetime) {
          newStop.type = 'Drop-off\nPickup'
        } else if (stop.dropoffDatetime) {
          newStop.type = 'Drop-off'
        } else if (stop.pickupDatetime) {
          newStop.type = 'Pickup'
        } else {
          newStop.type = '--'
        }
        newStop.pickupDatetime = stop.pickupDatetime
        newStop.dropoffDatetime = stop.dropoffDatetime
        return newStop
      })

      const vehicleAssignmentsData = await getTripAssignmentsForReservation({
        reservationIds: [reservation.reservationId],
      })
      const tripAssignments = vehicleAssignmentsData.data.vehicleAssignments

      const component = () => import('@/components/ReservationMapSidebar.vue')
      this.$store.dispatch('app/openDialog', {
        data: {
          active: true,
          isLoading: false,
          reservationId: reservation.reservationId,
          managedId: reservation.managedId,
          reservationStatus: reservation.reservationStatus,
          journeys: reservation.journeys,
          startDate: reservation.startDate,
          tripVehicleGroups: tripVehicleGroups,
          stops: reservation.stops,
          referredProviders,
          fullTripVehicleGroups: reservation.tripVehicleGroups,
          trackingAllocation,
          trackingReservationStatus,
          tripAssignments,
          itineraryItems,
          linkToReservationDetail: true,
        },
        component,
      })
    },
    findVehicleTrackingDevice(vehicleId) {
      const foundDevice = this.lastVehiclePositions.find(
        (position) => position.vehicleId == vehicleId
      )
      return foundDevice || null
    },
    openReservationDetail(reservationId) {
      const routeData = this.$router.resolve({
        name: 'reservation-detail',
        params: {
          id: reservationId,
          ignoreCache: true,
        },
      })
      window.open(routeData.href, '_blank')
    },
    hasRecentTrackingData(vehicleId) {
      const foundDevice = this.lastVehiclePositions.find(
        (position) => position.vehicleId == vehicleId
      )
      if (!foundDevice?.lastTransmitted) {
        return false
      }

      const lastSyncDateTime = DateTime.fromISO(foundDevice.lastTransmitted)
      const trackingDataInterval = Interval.fromDateTimes(
        lastSyncDateTime,
        DateTime.utc()
      )

      return trackingDataInterval.length('minutes') <= RECENT_TRACKING_MINUTES
    },
    reservationStatusColor(status) {
      switch (status) {
        case 'started':
          return 'green'
        case 'upcoming':
          return 'blue'
        case 'finished':
          return 'gray-dark'
        default:
          return 'gray-dark'
      }
    },
    nextStop(stops) {
      return (
        stops
          ?.filter((stop) => !stop.arrived)
          .sort((a, b) => a.stopIndex - b.stopIndex)?.[0] || null
      )
    },
    formatTime(stop) {
      const time = stop?.pickupDateTime || stop?.dropoffDateTime
      if (!time) {
        return ''
      }
      return DateTime.fromISO(time, { zone: stop?.address?.timeZone }).toFormat(
        'hh:mm a'
      )
    },
    deviceReportTime(device) {
      if (!device) {
        return ''
      }
      if (
        DateTime.fromISO(device.lastTransmitted) <
        DateTime.local().minus({ days: 1 })
      ) {
        return DateTime.fromISO(device.lastTransmitted).toFormat('yyyy-MM-dd')
      }
      return timeAgo(device.lastTransmitted)
    },
    refresh() {
      this.computeRefreshInterval()
      this.getTrackingForAllVehicles()
    },
    computeRefreshInterval() {
      const resStatuses = this.filteredVehicles.map(
        (vehicle) => vehicle.reservation?.reservationStatus
      )
      const finishedCount = resStatuses.filter(
        (status) => status === 'finished'
      ).length
      this.standardRefreshInterval =
        resStatuses.length === finishedCount ? 30 : 10
    },
    computeHeading(vehicle) {
      let heading = vehicle.gpsHeading
      heading = heading % 360
      if (heading < 0) {
        heading += 360
      }
      if (heading !== 0) {
        heading = (parseInt(heading / 10, 10) + 1) * 10
      }
      if (heading === 360) {
        heading -= 360
      }
      return heading
    },
    headingToDirection(heading) {
      const val = Math.floor(heading / 22.5 + 0.5)
      const directions = [
        'N',
        'NNE',
        'NE',
        'ENE',
        'E',
        'ESE',
        'SE',
        'SSE',
        'S',
        'SSW',
        'SW',
        'WSW',
        'W',
        'WNW',
        'NW',
        'NNW',
      ]
      return directions[val % 16]
    },
    clearSelectedReservation() {
      this.selectedReservationId = null
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-card {
  border-color: $input-border-gray;
}
.reservation-card:hover {
  border-color: $blue-dull;
}
.hover {
  border-color: $blue-dull;
}
</style>
