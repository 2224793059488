<template>
  <div>
    <v-tabs v-model="selectedTab" class="mb-4">
      <template v-for="component in contractComponents">
        <v-tab :key="component.text">
          {{ component.text }}
        </v-tab>
      </template>
    </v-tabs>
    <keep-alive>
      <component
        :is="contractComponents[selectedTab].value"
        :id="`${id}-contract-component-selector`"
        :contract="contract"
        @refresh="$emit('refresh')"
      />
    </keep-alive>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import ContractQuotes from '@/components/ContractQuotes.vue'
import ContractReservationList from '@/components/ContractReservationList.vue'
import ContractPayments from '@/components/ContractPayments.vue'
import ContractReferrals from '@/components/ContractReferrals.vue'
import ContractNotes from '@/components/ContractNotes.vue'
import ContractTracking from '@/components/ContractTracking.vue'
import ContractAlerts from '@/components/ContractAlerts.vue'

export default {
  props: {
    contract: {
      type: Object,
      default: () => ({}),
    },
    customerAccount: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedTab: 0,
      contractComponents: [
        { text: 'Quotes', value: ContractQuotes },
        { text: 'Reservations', value: ContractReservationList },
        { text: 'Referrals', value: ContractReferrals },
        { text: 'Payments', value: ContractPayments },
        { text: 'Notes', value: ContractNotes },
        { text: 'Alerts', value: ContractAlerts },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    const { getters = {} } = this.$store
    this.trackingTabDisabled =
      getters['systemParameters/contractTrackingTabDisabled']
    if (!this.trackingTabDisabled) {
      this.contractComponents.push({
        text: 'Tracking',
        value: ContractTracking,
      })
    }
  },
}
</script>
