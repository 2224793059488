<template>
  <div>
    <v-menu :close-on-click="true" :close-on-content-click="true">
      <template #activator="{ on, attrs }">
        <v-btn
          :id="`contract-reservations-list-button-actions`"
          class="btn-secondaryaction"
          v-bind="attrs"
          v-on="on"
        >
          Actions
          <v-icon>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <template v-for="option in multiselectActions">
          <v-list-tile
            :id="option.id"
            :key="option.id"
            :class="isDisabled ? 'disabled' : ''"
            @click="handleMultiselectClick(option)"
          >
            <v-list-tile-title>
              {{ option.text }}
            </v-list-tile-title>
          </v-list-tile>
        </template>
      </v-list>
    </v-menu>
    <PaymentSidebarWrapper
      v-model="dialogMode"
      :disable-email="true"
      :rows="selectedRows"
      :contract="contract"
      :max-width="dialogMode === 'offerReferrals' ? 700 : 500"
      @close-modal="closeDialog"
      @remove-item="removeItem"
    />
  </div>
</template>

<script>
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'
import admin from '@/services/admin'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    PaymentSidebarWrapper,
  },
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
    selectedRows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      sidebarDialog: false,
      dialogMode: null,
      multiselectActions: [
        {
          id: 'contract-reservations-offer-referrals',
          action: this.offerReferrals,
          text: 'Offer Referrals',
          mode: 'offerReferrals',
        },
        {
          id: 'contract-reservations-accept-referrals',
          action: this.acceptReferrals,
          text: 'Accept Referrals',
          mode: 'accept-referrals',
        },
        {
          id: 'contract-reservations-reject-referrals',
          action: this.rejectReferrals,
          text: 'Reject Referrals',
          mode: 'reject-referrals',
        },
        {
          id: 'contract-reservations-send-driver-info',
          action: this.sendDriverInfo,
          text: 'Send Driver Info',
          mode: 'send-driver-info',
        },
        {
          id: 'contract-reservations-collect-payment',
          action: this.collectPayment,
          text: 'Collect Payment',
          mode: 'collectPayment',
        },
        {
          id: 'contract-reservations-invoice',
          action: this.invoice,
          text: 'Invoice',
          mode: 'invoice',
        },
        {
          id: 'contract-reservations-add-charge',
          action: this.addCharge,
          text: 'Add Charge',
          mode: 'addCharge',
        },
        {
          id: 'contract-reservations-reduce-charge',
          action: this.reduceCharge,
          text: 'Reduce Charge',
          mode: 'reduceCharge',
        },
        {
          id: 'contract-reservations-add-refund',
          action: this.addRefund,
          text: 'Add Refund',
          mode: 'addRefund',
        },
        {
          id: 'contract-reservations-change-due-date',
          action: this.changeDueDate,
          text: 'Change Due Date',
          mode: 'changeDueDate',
        },
      ],
    }
  },
  computed: {
    isDisabled() {
      return this.selectedRows.length === 0
    },
  },
  methods: {
    offerAllReferrals() {},
    offerReferrals() {},
    async acceptReferrals() {
      const reservationIds = this.selectedRows.map((row) => {
        return row.item.reservationId
      })
      const payload = {
        reservationIds: reservationIds,
        contractId: this.contract.contractId,
      }

      const response = await admin.acceptReferralsEnMasse(payload)

      this.closeDialog()
      if (response?.status == 200) {
        this.$store.dispatch('app/showAlert', {
          message: 'Referrals accepted.',
        })
      } else {
        this.$store.dispatch('app/showAlert', {
          message: 'Unable to accept referrals.',
        })
      }
      this.$nextTick(() => this.$forceUpdate())
    },
    async rejectReferrals() {
      const reservationIds = this.selectedRows.map((row) => {
        return row.item.reservationId
      })
      const payload = {
        parentReservationIds: reservationIds,
        contractId: this.contract.contractId,
      }

      const response = await admin.rejectReferralsEnMasse(payload)

      this.closeDialog()
      if (response?.status == 200) {
        this.$store.dispatch('app/showAlert', {
          message: 'Referrals rejected.',
        })
      } else {
        this.$store.dispatch('app/showAlert', {
          message: 'Unable to reject referrals.',
        })
      }
      this.$nextTick(() => this.$forceUpdate())
    },
    sendDriverInfo() {
      this.$store.dispatch('app/openDialog', {
        component: () => import('./ReservationSendDriverInfo.vue'),
        data: {
          reservationList: this.selectedRows,
        },
      })
    },
    async collectPayment() {},
    invoice() {},
    addCharge() {},
    reduceCharge() {},
    addRefund() {},
    changeDueDate() {},
    handleMultiselectClick(option) {
      if (!this.isDisabled) {
        this.dialogMode = option.mode
        option.action()
        if (
          option.mode === 'accept-referrals' ||
          option.mode === 'reject-referrals'
        ) {
          this.dialog = false
        } else {
          this.sidebarDialog = true
        }
      }
    },
    closeDialog() {
      this.dialog = false
      this.sidebarDialog = false
      EventBus.$emit('global-table-view-refresh')
    },
    removeItem(item) {
      this.$emit('unselect-row', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
  .disabled {
    ::v-deep .v-list__tile {
      cursor: default;
      background: rgba($border-gray, 0.5) !important;
    }
  }
}
</style>
