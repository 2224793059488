<template>
  <div>
    <div class="contract-detail-notes">{{ contract.contractNotes }}</div>
  </div>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.contract-detail-notes {
  white-space: pre;
  margin: 0 8px 8px;
  padding: 16px;
  border: 1px solid $border-gray;
}
</style>
