import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Alert } from '@/models/Alert'

const httpService: HttpService = new HttpService()

export default {
  getAlertsTable(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Alert>>> {
    const {
      sorts,
      filters,
      additionalQueries,
      pageSize = 10,
      page = 1,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/alerts?${query}`

    return httpService.get(url)
  },
  getAlert(externalId: number): Promise<AxiosResponse<Alert>> {
    const host = baseUrl()
    const url = `https://${host}/alerts/${externalId}`

    return httpService.get(url)
  },
  createAlert(payload: Alert): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/alerts`

    return httpService.post(url, payload)
  },
  updateAlert(params: { externalId: number, payload: Alert }): Promise<AxiosResponse> {
    const { externalId, payload } = params
    const host = baseUrl()
    const url = `https://${host}/alerts/${externalId}`

    return httpService.put(url, payload)
  },
  deleteAlert(externalId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/alerts/${externalId}`

    return httpService.delete(url)
  },
}
