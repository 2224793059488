import Vue from 'vue'
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helpers'

const MenuDataMapper = function (row) {
  const menuOut = []
  if (!row.isConverted) {
    menuOut.push({
      icon: () => 'edit',
      title: () => 'Edit Quote',
      quickAccess: true,
      path: () => {
        return `/quotes/edit/${row.quoteId}`
      },
    })
  }

  return menuOut
}
// collectionSingular
export default function (component) {
  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...authComputed,
    },
    methods: {
      ...mapActions({
        showAlert: 'app/showAlert',
        duplicateQuote: 'quotes/duplicateQuote',
        getV2Quote: 'quotes/getV2Quote',
      }),
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(this.row),
        },
      })
    },
  })
}
