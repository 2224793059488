<template>
  <v-layout class="action-links" justify-end row>
    <div
      v-for="(item, idx) in menu.filter((m) => m.quickAccess)"
      :key="idx"
      class="action-link-container"
      :style="item.icon() === 'credit_card' ? 'margin-right: 5px;' : ''"
    >
      <v-tooltip top>
        <template #activator="action">
          <span
            style="max-height: 24px"
            v-on="action.on"
            @click.stop="item.click"
          >
            <CRIcon
              v-if="item.click"
              :id="`${rowProps.index}-table-actions-icon-toggle-activate-${idx}`"
              class="cursor-pointer"
              :key="
                item.icon() === 'affiliate_management'
                  ? `item-${idx}-${item.color()}`
                  : `item-${idx}`
              "
              :color="(item.color && item.color()) || 'primary'"
              :view-box="item.viewBox"
              :width="24"
              :height="24"
              :file-name="snakeToPascal(item.icon())"
            />
            <router-link
              v-if="item.path"
              :id="`${rowProps.index}-table-actions-link-icon-path-${idx}`"
              target="_blank"
              :to="{ path: item.path() }"
              class="action-link"
            >
              <CRIcon
                :color="(item.color && item.color()) || 'primary'"
                :view-box="item.viewBox"
              >
                {{ item.icon() }}
              </CRIcon>
            </router-link>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
    <div>
      <v-menu bottom transition="slide-x-transition">
        <template #activator="{ on: { click } }">
          <CRIcon
            :id="`${rowProps.index}-table-more-actions-menu`"
            class="cursor-pointer"
            color="primary"
            view-box="0 0 24 24"
            style="max-height: 24px"
            @click.native="click"
          >
            more_vert
          </CRIcon>
        </template>
        <v-list>
          <template
            v-for="(item, i) in menu.filter((m) =>
              m.condition ? m.condition() : true
            )"
          >
            <v-list-tile
              v-if="item.condition ? item.condition() : true"
              :id="`${rowProps.index}-table-more-actions-menu-item-${
                item.title ? toKebab(item.title()) : i
              }`"
              :key="`${rowProps.index}-table-more-actions-menu-item-${i}`"
              @click="handleClick(item)"
            >
              <v-list-tile-title>
                <div class="action-link">
                  <CRIcon
                    v-if="item.icon"
                    :color="(item.color && item.color()) || 'primary'"
                    :view-box="item.viewBox"
                  >
                    {{ item.icon() }}
                  </CRIcon>
                  <span class="action-text">{{ item.title() }}</span>
                </div>
              </v-list-tile-title>
            </v-list-tile>
          </template>
        </v-list>
      </v-menu>
    </div>
  </v-layout>
</template>

<script>
import { toKebab } from '../utils/string'
import { snakeToPascal } from '@/utils/string'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      resStatusMap: {
        finished: 'completed',
        started: 'in-progress',
        cancelled: 'upcoming',
        hold: 'upcoming',
      },
    }
  },
  mounted() {},
  methods: {
    snakeToPascal,
    toKebab,
    getConfirmReferralUrl() {
      return `/referral/${this.row.reservationId}/compare-affiliates`
    },
    referralStatusIconColor() {
      if (
        ['fully_accepted', 'fully_confirmed'].includes(this.row?.referralStatus)
      ) {
        return 'success'
      }
      if (['fully_offered'].includes(this.row?.referralStatus)) {
        return 'warning'
      }
      return 'error'
    },
    handleClick(item) {
      if (item.path) {
        this.$router.push({ path: item.path() })
      } else if (item.href) {
        window.open(item.href(), '_self')
      } else if (item.click) {
        item.click()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.action-links {
  height: 22px;
}

.action-links a,
.action-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
}

.action-text {
  padding-left: 8px;
  font-size: 12px;
  text-decoration: none;
  color: $primary;
}

.action-link-container {
  height: 22px;
  margin-right: 3px;

  &:last-child {
    margin-right: 0;
  }
}
</style>
