<template>
  <div>
    <div v-if="column.displayType === 'clickable-reservation-link-new'">
      <div :key="row.reservationManagedId">
        <router-link
          :id="`${id}-actionable-column-link-reservation-new-tab`"
          target="_blank"
          :to="{ path: `/reservations/${row.reservationManagedId}` }"
          class="no-underline"
          @click="(event) => event.stopPropagation()"
        >
          <span>{{ row.reservationManagedId }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...authComputed,
  },
  methods: {},
}
</script>

<style lang="scss">
.no-underline {
  text-decoration: none;
}
</style>
