<template>
  <v-layout class="action-links" row>
    <div
      v-for="(item, idx) in menu.filter((m) => m.quickAccess)"
      :id="`quotes-table-actions-col-${rowProps.index}-action-link-${idx}`"
      :key="row.quoteId + idx"
      class="action-link-container"
    >
      <v-tooltip top>
        <template #activator="action">
          <span v-on="action.on">
            <v-icon
              v-if="item.click"
              :id="`quote-form-${rowProps.index}-table-row-icon-${idx}`"
              :class="item.icon()"
              :color="item.color ? item.color() : 'primary'"
              @click="item.click"
            >
              {{ item.icon() }}
            </v-icon>
            <router-link
              v-if="item.path"
              :id="`quote-form-${rowProps.index}-table-row-router-link-${idx}`"
              :to="{ path: item.path() }"
              class="action-link"
              :target="item.target ? item.target : '_self'"
            >
              <v-icon
                :class="item.icon()"
                :color="item.color ? item.color() : 'primary'"
              >
                {{ item.icon() }}
              </v-icon>
            </router-link>
            <a
              v-if="item.href"
              :id="`quote-form-${rowProps.index}-table-row-action-link-${idx}`"
              :href="item.href()"
              class="action-link"
              :target="item.target ? item.target : '_self'"
            >
              <v-icon
                :class="item.icon()"
                :color="item.color ? item.color() : 'primary'"
              >
                {{ item.icon() }}
              </v-icon>
            </a>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
    <div>
      <v-menu bottom transition="slide-x-transition">
        <template #activator="more">
          <v-icon
            :id="`quote-form-${rowProps.index}-table-row-kebab-menu`"
            color="primary"
            v-on="more.on"
          >
            more_vert
          </v-icon>
        </template>
        <v-list>
          <template
            v-for="(item, i) in menu.filter((m) =>
              m.condition ? m.condition() : true
            )"
          >
            <v-list-tile
              v-if="item.condition ? item.condition() : true"
              :id="`quote-form-${
                rowProps.index
              }-table-row-action-list-tile-${item.title()}`"
              :key="i"
              @click="handleClick(item)"
            >
              <v-list-tile-title>
                <div class="action-link">
                  <v-icon
                    v-if="item.icon"
                    :color="item.color ? item.color() : 'primary'"
                  >
                    {{ item.icon() }}
                  </v-icon>
                  <span class="action-text">{{ item.title() }}</span>
                </div>
              </v-list-tile-title>
            </v-list-tile>
          </template>
        </v-list>
      </v-menu>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(item) {
      if (item.path) {
        this.$router.push({ path: item.path() })
      } else if (item.href) {
        window.open(item.href(), '_self')
      } else if (item.click) {
        item.click()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.action-link,
.action-links a {
  display: flex;
  align-items: center;

  i.icon-credit-card {
    padding-right: 2px;
  }
}

.action-text {
  padding-left: 8px;
  font-size: 12px;
  text-decoration: none;
  color: $primary;
}

.action-link-container {
  margin-right: 2px;

  &:last-child {
    margin-right: 0;
  }
}
</style>
