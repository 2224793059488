<template>
  <Sheet id="contract-detail" class="reservation-detail">
    <v-layout row wrap align-center justify-end>
      <v-flex>
        <h1 style="display: inline-block">
          Contract{{
            contract
              ? ` #${contract.contractId} - ${contract.contractName}`
              : ''
          }}
        </h1>
      </v-flex>
      <v-flex shrink>
        <ContractActionsMenu
          v-if="contract"
          :contract="contract"
          @refresh="loadContract"
        />
      </v-flex>
    </v-layout>
    <br />
    <ContractOverview
      v-if="contract"
      id="contract-detail-overview"
      :contract="contract"
    />
    <br />
    <ContractComponentSelector
      v-if="contract"
      id="contract-detail-components"
      :contract="contract"
      @refresh="loadContract"
    />
  </Sheet>
</template>

<script>
import contracts from '@/services/contracts'
import { authComputed } from '@/state/helpers'
import { capitalize } from '@/utils/string'
import * as logger from '@/utils/logger'

import Sheet from '@/layouts/Sheet.vue'
import ContractActionsMenu from '@/components/ContractActionsMenu.vue'
import ContractOverview from '@/components/ContractOverview.vue'
import ContractComponentSelector from '@/components/ContractComponentSelector.vue'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    Sheet,
    ContractActionsMenu,
    ContractOverview,
    ContractComponentSelector,
  },
  metaInfo() {
    return {
      title: `Contract ${this.contractId}`,
    }
  },
  data() {
    return {
      doesContractExist: 0,
      routeParamContractId: undefined,
      contract: null,
      markAcceptedLoading: false,
    }
  },
  computed: {
    ...authComputed,
    contractId() {
      return this.contract?.contractId
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        return
      }
      Object.assign(this.$data, this.$options.data())
      this.resetContractDetail()
    },
  },
  async mounted() {
    this.loadContract()
    EventBus.$on('global-table-view-refresh', async () => {
      await this.loadContract()
    })
  },
  methods: {
    capitalize,
    setContractData(contractData) {
      this.contract = Object.assign({}, this.contract, contractData)
    },
    async loadContract() {
      const contractData = await contracts.getContract(this.$route.params.id)
      this.setContractData(contractData.data)
    },
    async resetContractDetail() {
      if (!this.contractsCache[this.$route.params.id]) {
        await this.$store.dispatch(
          'contract/getContract',
          this.$route.params.id
        )
      } else {
        this.setContractData(this.contractsCache[this.$route.params.id])
      }
    },
    async markAccepted(referralAcceptanceESignature = '') {
      this.markAcceptedLoading = true
      const params = {
        reservationId: this.reservation.reservationId,
        referralId: this.reservation.referralId,
        companyId: this.currentUser?.companyId,
        refetchId: this.reservation.reservationId,
        referralAcceptanceESignature,
      }
      try {
        await this.$store.dispatch('reservations/acceptReferral', params)
      } catch (err) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error accepting referral, please try again',
        })
        logger.error(err)
        this.markAcceptedLoading = false
      }
      this.markAcceptedLoading = false
    },
    async markRejected() {
      this.markAcceptedLoading = true
      const payload = {
        reservationId: this.reservation.reservationId,
        referralId: this.reservation.referralId,
        companyId: this.currentUser?.companyId,
        refreshReservationId: this.reservation.reservationId,
      }
      try {
        await this.$store.dispatch('reservations/rejectReferral', payload)
      } catch (err) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error rejecting referral, please try again',
        })
        logger.error(err)
        this.markAcceptedLoading = false
      }

      this.isActionInProgress = false
      this.$router.push({ name: 'reservations' })
    },
    updateCachedReservation() {
      this.$store.dispatch('reservations/refreshReservationById', {
        reservationId: this.$route.params.id,
        companyId: this.currentUser?.companyId,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  margin: 0;
}

.offer-btn {
  min-height: 40px;
  color: $white;
}

.layout.row > *:not(:first-child) {
  margin-right: 0;
  margin-left: 8px;
}
.alert {
  font-size: 14px;
  color: $red;
  .v-icon {
    color: $red;
  }
}
</style>
