<template>
  <BaseTableWrapper
    :columns="columns"
    :table-init="tableInit"
    :enable-export="false"
    :initial-filters="initialFilters"
  />
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import {
  datePredefined,
  noFutureDatesPredefined,
  userPredefined,
} from '@/utils/predefined'
import AlertActionableColumn from './AlertActionableColumn.vue'
import { isoToString } from '@/utils/time'
import alerts from '@/services/alerts'
import GenericViewActionWrapper from '@/utils/GenericViewActionWrapper'
import ViewTableItem from './ViewTableItem.vue'

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detail: null,
        detailKeyId: 'externalId',
        tableId: 'alerts_tv_view',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        addNewEnabled: true,
        addNewHandler: () => this.addAlert(),
        currentPage: 1,
        perPage: 10,
        isDetailed: false,
        columns: [],
        collection: 'Alerts',
        action: (params) => alerts.getAlertsTable(params),
      },
    }
  },
  async mounted() {
    const columns = [
      {
        _t_id: 'f95a853b-ace1-472c-a3c1-cdbf9dbca63e',
        prop: '/',
        text: 'Actions',
        component: GenericViewActionWrapper(
          ViewTableItem,
          this.handleViewAlert
        ),
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
      },
      {
        _t_id: '25591e41-832d-49bb-bdc0-58118c141b9e',
        prop: 'title',
        text: 'Title',
        sort: false,
        filter: true,
        type: 'text',
        filterType: 'contains',
        filterProp: 'title',
      },
      {
        _t_id: 'c057fea0-204d-43f2-8ef8-e4ac368fccb5',
        prop: 'startEffectiveDate',
        text: 'Start Date',
        computedText: (item) => (item ? isoToString(item) : ''),
        sort: true,
        filter: true,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: deepClone(datePredefined),
        defaultSort: true,
      },
      {
        _t_id: 'a82c59c1-45c2-43e6-939a-14e07956a490',
        prop: 'endEffectiveDate',
        text: 'End Date',
        computedText: (item) => (item ? isoToString(item) : ''),
        sort: true,
        filter: true,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: deepClone(datePredefined),
      },
      {
        _t_id: 'f0c8e832-05d4-4181-93cc-7e89f6b112b5',
        prop: 'reservationManagedId',
        component: AlertActionableColumn,
        text: 'Reservation',
        sort: false,
        filter: true,
        type: 'text',
        displayType: 'clickable-reservation-link-new',
        filterType: 'contains',
        filterProp: 'reservationManagedId',
      },
      {
        _t_id: '395aba10-cd44-419b-a774-46a2d3fea590',
        prop: 'enabled',
        text: 'Enabled',
        type: 'text',
        filterType: 'eq',
        sort: true,
        filter: true,
        computedText: (item) => (item ? 'Yes' : 'No'),
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        predefined: [
          {
            text: 'Yes',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: true,
          },
          {
            text: 'No',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            value: false,
          },
        ],
      },
      {
        _t_id: '1dabf8b2-0146-4a40-bd33-36840a9a908e',
        prop: 'createdByUser',
        text: 'Created By',
        sort: true,
        filter: true,
        computedText: (item) =>
          item ? `${item.firstName} ${item.lastName}` : '',
        type: 'text',
        filterType: 'contains',
        filterProp: 'createdByUserName',
        predefined: userPredefined,
      },
      {
        _t_id: '519c5454-0e34-4ce4-8446-027a275088fc',
        prop: 'createdAt',
        text: 'Created On',
        computedText: (item) =>
          DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
        sort: true,
        filter: true,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: deepClone(noFutureDatesPredefined),
        defaultSort: true,
      },
      {
        _t_id: 'contract_id_filter',
        filterType: 'eq',
        prop: 'contractId',
        type: 'number',
        filterPlaceholder: true,
      },
    ]
    this.tableInit.columns.push(...columns)
    this.initialFilters = [
      {
        column: columns.find((column) => column._t_id === 'contract_id_filter'),
        value: this.contract?.contractId,
        hideOnFilterBar: true,
      },
    ]
  },
  methods: {
    addAlert() {
      const component = () => import('@/components/AlertSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          mode: 'add',
          title: 'Add Alert',
          contract: this.contract,
        },
        component,
      })
    },
    handleViewAlert(row) {
      const component = () => import('@/components/AlertSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          mode: 'view',
          title: 'View Alert',
          contract: this.contract,
          externalId: row.externalId,
        },
        component,
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
