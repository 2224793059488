export default [
  null,
  'M19.8,30.9V15.1l-4.2,2.4l-1.1-2.7l5.9-3.2h2.7v19.3H19.8z',
  'M14.4,30.9v-2.5c1-0.8,1.8-1.5,2.5-2.1c0.7-0.6,1.4-1.2,1.9-1.7c0.6-0.5,1.1-1,1.6-1.5c0.8-0.8,1.5-1.6,2-2.2 c0.5-0.6,0.9-1.2,1.1-1.8c0.2-0.6,0.4-1.1,0.4-1.7c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.5-0.6-0.9-1.1-1.1c-0.5-0.3-1-0.4-1.6-0.4 c-0.8,0-1.6,0.3-2.2,0.8c-0.6,0.5-1,1.3-1.3,2.2l-3.1-0.6c0.2-1.1,0.7-2,1.3-2.8c0.6-0.8,1.4-1.4,2.3-1.9c0.9-0.4,1.9-0.7,3-0.7 c0.9,0,1.8,0.2,2.5,0.5c0.8,0.3,1.4,0.7,2,1.3c0.6,0.6,1,1.2,1.3,1.9c0.3,0.7,0.5,1.5,0.5,2.4c0,0.8-0.1,1.6-0.4,2.4 c-0.3,0.8-0.8,1.6-1.4,2.4c-0.6,0.8-1.5,1.7-2.5,2.7c-1,1-2.2,2.1-3.6,3.2h8.3v2.9H14.4z',
  'M20.8,31.3c-1.1,0-2.1-0.2-3.1-0.6c-0.9-0.4-1.8-1-2.4-1.7c-0.7-0.8-1.2-1.7-1.4-2.8l3-0.6c0.3,0.9,0.8,1.6,1.5,2.1 s1.5,0.7,2.4,0.7c0.7,0,1.3-0.1,1.8-0.4s1-0.6,1.3-1.1c0.3-0.5,0.5-1,0.5-1.6c0-0.6-0.2-1.1-0.5-1.6c-0.3-0.5-0.7-0.8-1.3-1 c-0.6-0.2-1.2-0.4-1.9-0.4h-2.1v-2.9h1.9c0.7,0,1.3-0.1,1.8-0.4c0.5-0.2,0.9-0.6,1.2-1c0.3-0.4,0.4-0.9,0.4-1.5 c0-0.5-0.1-1-0.4-1.4c-0.3-0.4-0.6-0.7-1.1-0.9c-0.5-0.2-1-0.3-1.6-0.3c-0.8,0-1.6,0.2-2.2,0.7c-0.6,0.5-1.1,1.1-1.4,2l-3-0.6 c0.2-1,0.7-1.9,1.3-2.6c0.6-0.7,1.4-1.3,2.4-1.8c0.9-0.4,2-0.6,3.1-0.6c0.9,0,1.7,0.1,2.5,0.4s1.4,0.6,2,1.1c0.6,0.5,1,1,1.3,1.7 c0.3,0.6,0.5,1.4,0.5,2.1c0,1-0.2,1.8-0.7,2.6c-0.5,0.7-1.2,1.3-2.1,1.8c1,0.4,1.8,1,2.4,1.8c0.6,0.8,0.9,1.8,0.9,2.9 c0,0.8-0.2,1.6-0.5,2.3c-0.3,0.7-0.8,1.3-1.4,1.9s-1.3,0.9-2.2,1.2C22.7,31.2,21.8,31.3,20.8,31.3z',
  'M22.7,30.9v-4.2h-9v-2.6l8.7-12.5h3.6v12.3h2.3v2.8h-2.3v4.2H22.7z M16.9,23.9h5.8v-8.3L16.9,23.9z',
  'M21,31.3c-1.1,0-2.1-0.2-3.1-0.6c-0.9-0.4-1.7-1-2.4-1.8c-0.7-0.8-1.1-1.6-1.4-2.6l3.1-0.5c0.3,0.8,0.8,1.5,1.4,2 c0.7,0.5,1.4,0.7,2.3,0.7c0.7,0,1.3-0.2,1.9-0.5c0.5-0.3,1-0.7,1.3-1.3c0.3-0.5,0.5-1.1,0.5-1.8c0-0.7-0.2-1.3-0.5-1.8 c-0.3-0.5-0.7-0.9-1.3-1.2c-0.5-0.3-1.2-0.4-1.8-0.4c-0.6,0-1.2,0.1-1.7,0.4c-0.6,0.2-1,0.5-1.4,0.9l-2.6-0.3V11.6h11.2v3h-8.1v5 c0.9-0.7,2-1.1,3.3-1.1c0.9,0,1.7,0.2,2.5,0.5s1.4,0.8,2,1.3c0.6,0.6,1,1.2,1.3,2c0.3,0.8,0.5,1.6,0.5,2.5c0,0.9-0.2,1.8-0.5,2.6 c-0.4,0.8-0.8,1.5-1.5,2.1s-1.4,1-2.2,1.4C22.9,31.1,22,31.3,21,31.3z',
  'M21.5,31.3c-1.1,0-2.1-0.2-3.1-0.7s-1.7-1.1-2.4-1.9c-0.7-0.8-1.2-1.8-1.5-3c-0.4-1.2-0.5-2.5-0.5-3.9 c0-1.5,0.2-3,0.6-4.3s1-2.4,1.8-3.4c0.8-1,1.7-1.7,2.8-2.2c1.1-0.5,2.3-0.8,3.6-0.8c0.8,0,1.6,0.1,2.4,0.3c0.7,0.2,1.4,0.5,2.1,1 l-1,2.7c-0.5-0.3-1-0.5-1.6-0.7c-0.6-0.2-1.2-0.3-1.8-0.3c-1,0-2,0.3-2.8,0.8c-0.8,0.5-1.5,1.3-2,2.3c-0.5,1-0.8,2.3-0.9,3.7 c0.5-0.8,1.1-1.4,1.9-1.8c0.8-0.4,1.8-0.7,2.8-0.7c0.9,0,1.8,0.2,2.5,0.5c0.8,0.3,1.5,0.8,2,1.3c0.6,0.6,1,1.2,1.4,2 c0.3,0.8,0.5,1.6,0.5,2.4c0,0.9-0.2,1.8-0.5,2.6c-0.3,0.8-0.8,1.5-1.5,2.1s-1.4,1.1-2.2,1.4S22.5,31.3,21.5,31.3z M21.5,28.4 c0.7,0,1.3-0.2,1.9-0.5c0.5-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9c0-0.7-0.2-1.3-0.5-1.8s-0.7-1-1.3-1.3 c-0.5-0.3-1.2-0.5-1.8-0.5c-0.7,0-1.3,0.2-1.8,0.5c-0.5,0.3-1,0.7-1.3,1.2c-0.3,0.5-0.5,1.1-0.5,1.8s0.2,1.3,0.5,1.9 c0.3,0.6,0.7,1,1.3,1.3C20.2,28.3,20.8,28.4,21.5,28.4z',
  'M16.8,30.9l7.1-16.3h-9.7v-3h13.3v2.5l-7.1,16.8H16.8z',
  'M21,31.3c-1,0-2-0.1-2.9-0.4c-0.9-0.3-1.6-0.7-2.2-1.2c-0.6-0.5-1.1-1.1-1.5-1.8c-0.3-0.7-0.5-1.5-0.5-2.3 c0-1.1,0.3-2.1,0.9-2.9c0.6-0.8,1.4-1.5,2.5-1.9c-0.9-0.4-1.5-1-2-1.8s-0.7-1.6-0.7-2.5c0-0.8,0.2-1.5,0.5-2.2 c0.3-0.7,0.8-1.2,1.4-1.7c0.6-0.5,1.3-0.8,2.1-1.1c0.8-0.3,1.7-0.4,2.6-0.4c0.9,0,1.8,0.1,2.6,0.4c0.8,0.3,1.5,0.6,2,1.1 c0.6,0.5,1,1,1.4,1.7s0.5,1.4,0.5,2.1c0,0.9-0.2,1.7-0.7,2.5c-0.5,0.7-1.1,1.3-2,1.8c1,0.4,1.9,1,2.4,1.8c0.6,0.8,0.9,1.8,0.9,2.9 c0,0.9-0.2,1.6-0.5,2.4c-0.3,0.7-0.8,1.3-1.5,1.9c-0.6,0.5-1.4,0.9-2.3,1.2C23,31.2,22,31.3,21,31.3z M21,28.5 c0.8,0,1.4-0.1,2-0.4c0.6-0.3,1-0.6,1.3-1.1s0.5-1,0.5-1.6c0-0.6-0.2-1.2-0.5-1.6c-0.3-0.5-0.8-0.8-1.3-1.1 c-0.6-0.3-1.2-0.4-2-0.4s-1.4,0.1-2,0.4c-0.6,0.3-1,0.6-1.3,1.1c-0.3,0.5-0.5,1-0.5,1.6c0,0.6,0.2,1.2,0.5,1.6s0.8,0.8,1.3,1.1 C19.6,28.3,20.2,28.5,21,28.5z M21,19.5c0.6,0,1.2-0.1,1.7-0.4c0.5-0.2,0.9-0.6,1.2-1s0.4-0.9,0.4-1.4c0-0.5-0.1-1-0.4-1.4 s-0.7-0.7-1.2-1C22.2,14.1,21.6,14,21,14s-1.2,0.1-1.7,0.4c-0.5,0.2-0.9,0.6-1.2,1c-0.3,0.4-0.4,0.9-0.4,1.4c0,0.5,0.1,1,0.4,1.4 c0.3,0.4,0.7,0.7,1.2,1C19.8,19.4,20.4,19.5,21,19.5z',
  'M19.2,31.3c-1.8,0-3.3-0.4-4.6-1.3l1-2.7c0.5,0.3,1,0.5,1.6,0.7c0.6,0.2,1.3,0.3,2,0.3c1,0,1.9-0.2,2.7-0.7s1.5-1.2,2-2.2 c0.5-1,0.8-2.3,0.9-4c-0.5,0.8-1.1,1.4-1.9,1.8C22,23.7,21.1,24,20,24c-0.9,0-1.8-0.2-2.5-0.5c-0.8-0.3-1.5-0.8-2-1.3 c-0.6-0.6-1-1.2-1.3-2s-0.5-1.6-0.5-2.5c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.5-2.1c0.6-0.6,1.4-1.1,2.2-1.4 c0.8-0.3,1.7-0.5,2.7-0.5c1.1,0,2.1,0.2,3.1,0.7c0.9,0.4,1.7,1.1,2.4,1.9c0.7,0.8,1.2,1.8,1.5,3c0.4,1.2,0.5,2.5,0.5,4.1 c0,1.7-0.2,3.1-0.6,4.5c-0.4,1.3-1,2.4-1.8,3.3c-0.8,0.9-1.7,1.6-2.8,2C21.7,31.1,20.5,31.3,19.2,31.3z M20.5,21.2 c0.7,0,1.3-0.2,1.8-0.5c0.5-0.3,1-0.7,1.3-1.2c0.3-0.5,0.5-1.1,0.5-1.8c0-0.7-0.2-1.3-0.5-1.9s-0.7-1-1.3-1.3 c-0.5-0.3-1.1-0.5-1.8-0.5s-1.3,0.2-1.8,0.5c-0.5,0.3-1,0.8-1.3,1.3c-0.3,0.6-0.5,1.2-0.5,1.9s0.2,1.3,0.5,1.8 c0.3,0.5,0.7,1,1.3,1.3C19.2,21.1,19.8,21.2,20.5,21.2z',
  'M32.7,17c-0.3-1.2-0.8-2.3-1.4-3.1c-0.6-0.9-1.4-1.5-2.3-2c-0.9-0.5-1.9-0.7-3-0.7c-1.1,0-2.1,0.2-3.1,0.7 c-0.9,0.5-1.7,1.1-2.4,2c-0.7,0.9-1.2,1.9-1.5,3.2c-0.3,1-0.4,2.1-0.5,3.4h-2v-8.8h-2.7l-5.9,3.2l1.1,2.7l4.2-2.4v15.8h3.2V20.4h2 c0,0.3,0,0.6,0,0.9c0,1.5,0.2,2.9,0.5,4.1c0.3,1.2,0.8,2.3,1.4,3.2c0.6,0.9,1.4,1.5,2.3,2c0.9,0.5,1.9,0.7,3,0.7 c1.1,0,2.2-0.2,3.1-0.7c0.9-0.5,1.7-1.1,2.3-2c0.6-0.9,1.1-2,1.5-3.2c0.3-1.3,0.5-2.7,0.5-4.2C33.2,19.6,33.1,18.2,32.7,17z M28.8,26.4c-0.7,1.2-1.7,1.8-2.9,1.8c-1.3,0-2.2-0.6-2.9-1.8c-0.7-1.2-1-3-1-5.2c0-2.3,0.4-4,1-5.2c0.7-1.2,1.7-1.8,2.9-1.8 c1.2,0,2.2,0.6,2.9,1.8s1,3,1,5.2C29.9,23.5,29.5,25.2,28.8,26.4z',
  'M25.3,11.6l-5.9,3.2l1.1,2.7l4.2-2.4v5.3h-6.6v-8.8h-2.7l-5.9,3.2l1.1,2.7l4.2-2.4v15.8h3.2V20.4h6.6c0,0,0,1.4,0,1.5 c0,0.1,0,9,0,9H28V11.6H25.3z',
  'M23.2,27.9c1.4-1.1,2.6-2.2,3.6-3.2c1-1,1.8-1.9,2.5-2.7c0.6-0.8,1.1-1.6,1.4-2.4c0.3-0.8,0.4-1.5,0.4-2.4 c0-0.9-0.2-1.7-0.5-2.4c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2.1,0.2-3,0.7 c-0.9,0.4-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.3,2.8l3.1,0.6c0.2-0.9,0.7-1.7,1.3-2.2c0.6-0.5,1.3-0.8,2.2-0.8 c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.6,1.1,1.1c0.3,0.5,0.4,1.1,0.4,1.7c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.4-0.5,0.9-0.8,1.3H16.3v-8.8 h-2.7l-5.9,3.2l1.1,2.7l4.2-2.4v15.8h3.2V20.4h10.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.5,0.6-1.2,1.4-2,2.2c-0.5,0.5-1,1-1.6,1.5 c-0.6,0.5-1.2,1.1-1.9,1.7c-0.7,0.6-1.6,1.3-2.5,2.1v2.5h13.2v-2.9H23.2z',
  'M30.7,22.5c-0.6-0.8-1.4-1.4-2.4-1.8c0.9-0.4,1.6-1,2.1-1.8c0.5-0.7,0.7-1.6,0.7-2.6c0-0.8-0.2-1.5-0.5-2.1 c-0.3-0.6-0.7-1.2-1.3-1.7c-0.6-0.5-1.2-0.8-2-1.1s-1.6-0.4-2.5-0.4c-1.1,0-2.2,0.2-3.1,0.6c-0.9,0.4-1.7,1-2.4,1.8 s-1.1,1.6-1.3,2.6l3,0.6c0.3-0.9,0.7-1.5,1.4-2c0.6-0.5,1.4-0.7,2.2-0.7c0.6,0,1.2,0.1,1.6,0.3c0.5,0.2,0.8,0.5,1.1,0.9 c0.3,0.4,0.4,0.8,0.4,1.4c0,0.6-0.1,1.1-0.4,1.5c-0.3,0.4-0.7,0.8-1.2,1c-0.5,0.2-1.1,0.4-1.8,0.4h-1.9v0.9h-6.2v-8.8h-2.7l-5.9,3.2 l1.1,2.7l4.2-2.4v15.8h3.2V20.4h6.2v1.9h2.1c0.7,0,1.4,0.1,1.9,0.4c0.5,0.2,1,0.6,1.3,1c0.3,0.5,0.5,1,0.5,1.6 c0,0.6-0.2,1.1-0.5,1.6c-0.3,0.5-0.7,0.8-1.3,1.1s-1.2,0.4-1.8,0.4c-0.9,0-1.7-0.2-2.4-0.7s-1.2-1.2-1.5-2.1l-3,0.6 c0.3,1.1,0.8,2,1.4,2.8c0.7,0.8,1.5,1.3,2.4,1.7c0.9,0.4,2,0.6,3.1,0.6c1,0,1.9-0.2,2.7-0.4c0.8-0.3,1.6-0.7,2.2-1.2 s1.1-1.1,1.4-1.9c0.3-0.7,0.5-1.5,0.5-2.3C31.6,24.3,31.3,23.4,30.7,22.5z',
  'M29.8,23.9V11.6h-3.6l-6.1,8.8h-4.2v-8.8h-2.7l-5.9,3.2l1.1,2.7l4.2-2.4v15.8h3.2V20.4h4.2l-2.5,3.6v2.6h9v4.2h3.2v-4.2h2.3 v-2.8H29.8z M26.6,23.9h-5.8l5.8-8.3V23.9z',
  'M31.4,22.3c-0.3-0.8-0.8-1.4-1.3-2c-0.6-0.6-1.2-1-2-1.3s-1.6-0.5-2.5-0.5c-1.3,0-2.4,0.4-3.3,1.1v-5h8.1v-3H19.2v8.8h-2.7 v-8.8h-2.7l-5.9,3.2L9,17.5l4.2-2.4v15.8h3.2V20.4h2.7v2l2.6,0.3c0.4-0.4,0.8-0.7,1.4-0.9c0.6-0.2,1.1-0.4,1.7-0.4 c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.8c0,0.7-0.2,1.3-0.5,1.8c-0.3,0.5-0.7,1-1.3,1.3 c-0.6,0.3-1.2,0.5-1.9,0.5c-0.9,0-1.6-0.2-2.3-0.7c-0.7-0.5-1.1-1.1-1.4-2l-3.1,0.5c0.3,1,0.7,1.9,1.4,2.6c0.7,0.8,1.4,1.3,2.4,1.8 c0.9,0.4,1.9,0.6,3.1,0.6c1,0,1.9-0.2,2.7-0.5c0.8-0.3,1.6-0.8,2.2-1.4s1.1-1.3,1.5-2.1c0.4-0.8,0.5-1.7,0.5-2.6 C31.8,23.9,31.7,23.1,31.4,22.3z',
  'M31.9,22.3c-0.3-0.8-0.8-1.4-1.4-2c-0.6-0.6-1.3-1-2-1.3c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2,0.2-2.8,0.7 c-0.8,0.4-1.5,1-1.9,1.8c0.1-1.5,0.4-2.7,0.9-3.7c0.5-1,1.1-1.8,2-2.3c0.8-0.5,1.7-0.8,2.8-0.8c0.6,0,1.2,0.1,1.8,0.3 c0.6,0.2,1.1,0.4,1.6,0.7l1-2.7c-0.7-0.4-1.4-0.8-2.1-1c-0.7-0.2-1.5-0.3-2.4-0.3c-1.3,0-2.5,0.3-3.6,0.8c-1.1,0.5-2,1.3-2.8,2.2 c-0.8,1-1.4,2.1-1.8,3.4c-0.3,0.9-0.5,1.8-0.6,2.8h-2.1v-8.8h-2.7l-5.9,3.2l1.1,2.7l4.2-2.4v15.8h3.2V20.4H18c0,0.5-0.1,1-0.1,1.5 c0,1.4,0.2,2.7,0.5,3.9c0.4,1.2,0.9,2.2,1.5,3c0.7,0.8,1.5,1.5,2.4,1.9s1.9,0.7,3.1,0.7c1,0,1.9-0.2,2.7-0.5s1.6-0.8,2.2-1.4 s1.1-1.3,1.5-2.1c0.3-0.8,0.5-1.7,0.5-2.6C32.3,23.8,32.2,23,31.9,22.3z M28.6,26.6c-0.3,0.6-0.8,1-1.3,1.3 c-0.6,0.3-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.3-1-0.8-1.3-1.3c-0.3-0.6-0.5-1.2-0.5-1.9s0.2-1.3,0.5-1.8 c0.3-0.5,0.7-0.9,1.3-1.2c0.5-0.3,1.2-0.5,1.8-0.5c0.7,0,1.3,0.2,1.8,0.5c0.5,0.3,1,0.7,1.3,1.3s0.5,1.1,0.5,1.8 C29,25.5,28.9,26.1,28.6,26.6z',
  'M19.2,11.6v3h9.7c0,0-0.9,2.1-1.1,2.6c-0.2,0.5-1.4,3.3-1.4,3.3h-8.8v-8.8h-2.7l-5.9,3.2l1.1,2.7l4.2-2.4v15.8h3.2V20.4h8.8 l-4.5,10.5h3.6l7.1-16.8v-2.5H19.2z',
  'M32.2,22.6c-0.6-0.8-1.4-1.4-2.4-1.8c0.9-0.4,1.5-1,2-1.8c0.5-0.7,0.7-1.6,0.7-2.5c0-0.8-0.2-1.5-0.5-2.1s-0.8-1.2-1.4-1.7 c-0.6-0.5-1.3-0.9-2-1.1c-0.8-0.3-1.6-0.4-2.6-0.4c-0.9,0-1.8,0.1-2.6,0.4c-0.8,0.3-1.5,0.6-2.1,1.1s-1,1-1.4,1.7 c-0.3,0.7-0.5,1.4-0.5,2.2c0,0.9,0.2,1.7,0.7,2.5c0.3,0.5,0.8,1,1.4,1.4H17v-8.8h-2.7l-5.9,3.2l1.1,2.7l4.2-2.4v15.8H17V20.4h4.5 c0.2,0.1,0.4,0.3,0.6,0.4c-1,0.4-1.9,1-2.5,1.9c-0.6,0.8-0.9,1.8-0.9,2.9c0,0.8,0.2,1.6,0.5,2.3c0.3,0.7,0.8,1.3,1.5,1.8 c0.6,0.5,1.4,0.9,2.2,1.2c0.9,0.3,1.8,0.4,2.9,0.4c1,0,2-0.1,2.9-0.4c0.9-0.3,1.6-0.7,2.3-1.2c0.6-0.5,1.1-1.1,1.5-1.9 c0.3-0.7,0.5-1.5,0.5-2.4C33,24.4,32.7,23.4,32.2,22.6z M23.1,15.3c0.3-0.4,0.7-0.7,1.2-1c0.5-0.2,1.1-0.4,1.7-0.4s1.2,0.1,1.7,0.4 c0.5,0.2,0.9,0.6,1.2,1s0.4,0.9,0.4,1.4c0,0.5-0.1,1-0.4,1.4s-0.7,0.7-1.2,1c-0.5,0.2-1.1,0.4-1.7,0.4s-1.2-0.1-1.7-0.4 c-0.5-0.2-0.9-0.6-1.2-1c-0.3-0.4-0.4-0.9-0.4-1.4C22.6,16.2,22.8,15.7,23.1,15.3z M29.2,27c-0.3,0.5-0.8,0.8-1.3,1.1 c-0.6,0.3-1.2,0.4-2,0.4s-1.4-0.1-2-0.4c-0.6-0.3-1-0.6-1.3-1.1s-0.5-1-0.5-1.6c0-0.6,0.2-1.2,0.5-1.6c0.3-0.5,0.8-0.8,1.3-1.1 c0.6-0.3,1.2-0.4,2-0.4s1.4,0.1,2,0.4c0.6,0.3,1,0.6,1.3,1.1c0.3,0.5,0.5,1,0.5,1.6C29.7,26,29.6,26.5,29.2,27z',
  'M32.4,16.7c-0.4-1.2-0.9-2.2-1.5-3c-0.7-0.8-1.5-1.5-2.4-1.9c-0.9-0.4-1.9-0.7-3.1-0.7c-1,0-1.9,0.2-2.7,0.5 c-0.8,0.3-1.6,0.8-2.2,1.4c-0.6,0.6-1.1,1.3-1.5,2.1c-0.3,0.8-0.5,1.7-0.5,2.6c0,0.9,0.2,1.7,0.5,2.5c0,0.1,0.1,0.2,0.1,0.2H17v-8.8 h-2.7l-5.9,3.2l1.1,2.7l4.2-2.4v15.8H17V20.4h2.2c0.3,0.7,0.7,1.2,1.2,1.7c0.6,0.6,1.3,1,2,1.3c0.8,0.3,1.6,0.5,2.5,0.5 c1.1,0,2-0.2,2.8-0.7c0.8-0.4,1.5-1,1.9-1.8c-0.1,1.7-0.4,3-0.9,4c-0.5,1-1.2,1.7-2,2.2s-1.7,0.7-2.7,0.7c-0.7,0-1.4-0.1-2-0.3 c-0.6-0.2-1.1-0.4-1.6-0.7l-1,2.7c1.3,0.9,2.9,1.3,4.6,1.3c1.3,0,2.5-0.2,3.5-0.7c1.1-0.5,2-1.1,2.8-2c0.8-0.9,1.4-2,1.8-3.3 c0.4-1.3,0.6-2.8,0.6-4.5C32.9,19.3,32.7,17.9,32.4,16.7z M28.5,19.5c-0.3,0.5-0.7,0.9-1.3,1.2c-0.5,0.3-1.2,0.5-1.8,0.5 c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.3-1-0.7-1.3-1.3c-0.3-0.5-0.5-1.1-0.5-1.8s0.2-1.3,0.5-1.9c0.3-0.6,0.7-1,1.3-1.3 c0.5-0.3,1.1-0.5,1.8-0.5s1.3,0.2,1.8,0.5c0.5,0.3,1,0.8,1.3,1.3S29,17,29,17.7C29,18.4,28.8,19,28.5,19.5z',
  'M34.6,17c-0.3-1.2-0.8-2.3-1.4-3.1c-0.6-0.9-1.4-1.5-2.3-2c-0.9-0.5-1.9-0.7-3-0.7c-1.1,0-2.1,0.2-3.1,0.7 c-0.9,0.5-1.7,1.1-2.4,2c-0.7,0.9-1.2,1.9-1.5,3.2c-0.3,1-0.4,2.1-0.5,3.4h-1.6c0.1-0.3,0.3-0.5,0.4-0.8c0.3-0.8,0.4-1.5,0.4-2.4 c0-0.9-0.2-1.7-0.5-2.4c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2.1,0.2-3,0.7 c-0.9,0.4-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.3,2.8l3.1,0.6c0.2-0.9,0.7-1.7,1.3-2.2c0.6-0.5,1.3-0.8,2.2-0.8 c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.6,1.1,1.1c0.3,0.5,0.4,1.1,0.4,1.7c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.6-0.6,1.2-1.1,1.8 c-0.5,0.6-1.2,1.4-2,2.2c-0.5,0.5-1,1-1.6,1.5c-0.6,0.5-1.2,1.1-1.9,1.7c-0.7,0.6-1.6,1.3-2.5,2.1v2.5h13.2v-2.9h-8.3 c1.4-1.1,2.6-2.2,3.6-3.2c1-1,1.8-1.9,2.5-2.7c0.4-0.5,0.8-1.1,1-1.6h1.6c0,0.3,0,0.6,0,0.9c0,1.5,0.2,2.9,0.5,4.1 c0.3,1.2,0.8,2.3,1.4,3.2c0.6,0.9,1.4,1.5,2.3,2c0.9,0.5,1.9,0.7,3,0.7c1.1,0,2.2-0.2,3.1-0.7c0.9-0.5,1.7-1.1,2.3-2 c0.6-0.9,1.1-2,1.5-3.2c0.3-1.3,0.5-2.7,0.5-4.2C35.1,19.6,35,18.2,34.6,17z M30.7,26.4c-0.7,1.2-1.7,1.8-2.9,1.8 c-1.3,0-2.2-0.6-2.9-1.8c-0.7-1.2-1-3-1-5.2c0-2.3,0.4-4,1-5.2c0.7-1.2,1.7-1.8,2.9-1.8c1.2,0,2.2,0.6,2.9,1.8s1,3,1,5.2 C31.8,23.5,31.4,25.2,30.7,26.4z',
  'M28.2,11.6l-5.9,3.2l1.1,2.7l4.2-2.4v5.3h-6.2c0.1-0.3,0.3-0.5,0.4-0.8c0.3-0.8,0.4-1.5,0.4-2.4c0-0.9-0.2-1.7-0.5-2.4 c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2.1,0.2-3,0.7c-0.9,0.4-1.7,1.1-2.3,1.9 c-0.6,0.8-1.1,1.7-1.3,2.8l3.1,0.6c0.2-0.9,0.7-1.7,1.3-2.2c0.6-0.5,1.3-0.8,2.2-0.8c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.6,1.1,1.1 c0.3,0.5,0.4,1.1,0.4,1.7c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.2,1.4-2,2.2c-0.5,0.5-1,1-1.6,1.5 c-0.6,0.5-1.2,1.1-1.9,1.7c-0.7,0.6-1.6,1.3-2.5,2.1v2.5h13.2v-2.9h-8.3c1.4-1.1,2.6-2.2,3.6-3.2c1-1,1.8-1.9,2.5-2.7 c0.4-0.5,0.8-1.1,1-1.6h6.2v10.5h3.2V11.6H28.2z',
  'M26.1,27.9c1.4-1.1,2.6-2.2,3.6-3.2c1-1,1.8-1.9,2.5-2.7c0.6-0.8,1.1-1.6,1.4-2.4c0.3-0.8,0.4-1.5,0.4-2.4 c0-0.9-0.2-1.7-0.5-2.4c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2.1,0.2-3,0.7 c-0.9,0.4-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.3,2.8l3.1,0.6c0.2-0.9,0.7-1.7,1.3-2.2c0.6-0.5,1.3-0.8,2.2-0.8 c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.6,1.1,1.1c0.3,0.5,0.4,1.1,0.4,1.7c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.4-0.5,0.9-0.8,1.3H19.5 c0.1-0.3,0.3-0.5,0.4-0.8c0.3-0.8,0.4-1.5,0.4-2.4c0-0.9-0.2-1.7-0.5-2.4c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3 c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2.1,0.2-3,0.7c-0.9,0.4-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.3,2.8l3.1,0.6 c0.2-0.9,0.7-1.7,1.3-2.2c0.6-0.5,1.3-0.8,2.2-0.8c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.6,1.1,1.1c0.3,0.5,0.4,1.1,0.4,1.7 c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.2,1.4-2,2.2c-0.5,0.5-1,1-1.6,1.5c-0.6,0.5-1.2,1.1-1.9,1.7 c-0.7,0.6-1.6,1.3-2.5,2.1v2.5h13.2v-2.9h-8.3c1.4-1.1,2.6-2.2,3.6-3.2c1-1,1.8-1.9,2.5-2.7c0.4-0.5,0.8-1.1,1-1.6h10.1 c-0.1,0.1-0.2,0.3-0.3,0.4c-0.5,0.6-1.2,1.4-2,2.2c-0.5,0.5-1,1-1.6,1.5c-0.6,0.5-1.2,1.1-1.9,1.7c-0.7,0.6-1.6,1.3-2.5,2.1v2.5 h13.2v-2.9H26.1z',
  'M33.7,22.5c-0.6-0.8-1.4-1.4-2.4-1.8c0.9-0.4,1.6-1,2.1-1.8c0.5-0.7,0.7-1.6,0.7-2.6c0-0.8-0.2-1.5-0.5-2.1 c-0.3-0.6-0.7-1.2-1.3-1.7c-0.6-0.5-1.2-0.8-2-1.1s-1.6-0.4-2.5-0.4c-1.1,0-2.2,0.2-3.1,0.6c-0.9,0.4-1.7,1-2.4,1.8 s-1.1,1.6-1.3,2.6l3,0.6c0.3-0.9,0.7-1.5,1.4-2c0.6-0.5,1.4-0.7,2.2-0.7c0.6,0,1.2,0.1,1.6,0.3c0.5,0.2,0.8,0.5,1.1,0.9 c0.3,0.4,0.4,0.8,0.4,1.4c0,0.6-0.1,1.1-0.4,1.5c-0.3,0.4-0.7,0.8-1.2,1c-0.5,0.2-1.1,0.4-1.8,0.4h-1.9v0.9h-6 c0.1-0.3,0.3-0.5,0.4-0.8c0.3-0.8,0.4-1.5,0.4-2.4c0-0.9-0.2-1.7-0.5-2.4c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3 c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2.1,0.2-3,0.7c-0.9,0.4-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.3,2.8l3.1,0.6 c0.2-0.9,0.7-1.7,1.3-2.2c0.6-0.5,1.3-0.8,2.2-0.8c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.6,1.1,1.1c0.3,0.5,0.4,1.1,0.4,1.7 c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.2,1.4-2,2.2c-0.5,0.5-1,1-1.6,1.5c-0.6,0.5-1.2,1.1-1.9,1.7 c-0.7,0.6-1.6,1.3-2.5,2.1v2.5h13.2v-2.9h-8.3c1.4-1.1,2.6-2.2,3.6-3.2c1-1,1.8-1.9,2.5-2.7c0.4-0.5,0.8-1.1,1-1.6h6v1.9h2.1 c0.7,0,1.4,0.1,1.9,0.4c0.5,0.2,1,0.6,1.3,1c0.3,0.5,0.5,1,0.5,1.6c0,0.6-0.2,1.1-0.5,1.6c-0.3,0.5-0.7,0.8-1.3,1.1 s-1.2,0.4-1.8,0.4c-0.9,0-1.7-0.2-2.4-0.7s-1.2-1.2-1.5-2.1l-3,0.6c0.3,1.1,0.8,2,1.4,2.8c0.7,0.8,1.5,1.3,2.4,1.7 c0.9,0.4,2,0.6,3.1,0.6c1,0,1.9-0.2,2.7-0.4c0.8-0.3,1.6-0.7,2.2-1.2s1.1-1.1,1.4-1.9c0.3-0.7,0.5-1.5,0.5-2.3 C34.6,24.3,34.3,23.4,33.7,22.5z',
  'M32.6,23.9V11.6H29l-6.1,8.8h-3.5c0.1-0.3,0.3-0.5,0.4-0.8c0.3-0.8,0.4-1.5,0.4-2.4c0-0.9-0.2-1.7-0.5-2.4 c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2.1,0.2-3,0.7c-0.9,0.4-1.7,1.1-2.3,1.9 c-0.6,0.8-1.1,1.7-1.3,2.8l3.1,0.6c0.2-0.9,0.7-1.7,1.3-2.2c0.6-0.5,1.3-0.8,2.2-0.8c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.6,1.1,1.1 c0.3,0.5,0.4,1.1,0.4,1.7c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.2,1.4-2,2.2c-0.5,0.5-1,1-1.6,1.5 c-0.6,0.5-1.2,1.1-1.9,1.7c-0.7,0.6-1.6,1.3-2.5,2.1v2.5h13.2v-2.9h-8.3c1.4-1.1,2.6-2.2,3.6-3.2c1-1,1.8-1.9,2.5-2.7 c0.4-0.5,0.8-1.1,1-1.6h3.5l-2.5,3.6v2.6h9v4.2h3.2v-4.2h2.3v-2.8H32.6z M29.4,23.9h-5.8l5.8-8.3V23.9z',
  'M34.3,22.3c-0.3-0.8-0.8-1.4-1.3-2c-0.6-0.6-1.2-1-2-1.3s-1.6-0.5-2.5-0.5c-1.3,0-2.4,0.4-3.3,1.1v-5h8.1v-3H22.1v8.8h-2.4 c0.1-0.3,0.3-0.5,0.4-0.8c0.3-0.8,0.4-1.5,0.4-2.4c0-0.9-0.2-1.7-0.5-2.4c-0.3-0.7-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3 c-0.8-0.3-1.6-0.5-2.5-0.5c-1.1,0-2.1,0.2-3,0.7c-0.9,0.4-1.7,1.1-2.3,1.9c-0.6,0.8-1.1,1.7-1.3,2.8l3.1,0.6 c0.2-0.9,0.7-1.7,1.3-2.2c0.6-0.5,1.3-0.8,2.2-0.8c0.6,0,1.2,0.1,1.6,0.4c0.5,0.3,0.8,0.6,1.1,1.1c0.3,0.5,0.4,1.1,0.4,1.7 c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.6-0.6,1.2-1.1,1.8c-0.5,0.6-1.2,1.4-2,2.2c-0.5,0.5-1,1-1.6,1.5c-0.6,0.5-1.2,1.1-1.9,1.7 c-0.7,0.6-1.6,1.3-2.5,2.1v2.5h13.2v-2.9h-8.3c1.4-1.1,2.6-2.2,3.6-3.2c1-1,1.8-1.9,2.5-2.7c0.4-0.5,0.8-1.1,1-1.6h2.4v2l2.6,0.3 c0.4-0.4,0.8-0.7,1.4-0.9c0.6-0.2,1.1-0.4,1.7-0.4c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,1,0.7,1.3,1.2c0.3,0.5,0.5,1.1,0.5,1.8 c0,0.7-0.2,1.3-0.5,1.8c-0.3,0.5-0.7,1-1.3,1.3c-0.6,0.3-1.2,0.5-1.9,0.5c-0.9,0-1.6-0.2-2.3-0.7c-0.7-0.5-1.1-1.1-1.4-2L21,26.3 c0.3,1,0.7,1.9,1.4,2.6c0.7,0.8,1.4,1.3,2.4,1.8c0.9,0.4,1.9,0.6,3.1,0.6c1,0,1.9-0.2,2.7-0.5c0.8-0.3,1.6-0.8,2.2-1.4 s1.1-1.3,1.5-2.1c0.4-0.8,0.5-1.7,0.5-2.6C34.7,23.9,34.6,23.1,34.3,22.3z',
]
