var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"column":""}},[_c('v-layout',{staticClass:"padding-y-4",attrs:{"row":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-flex',{staticClass:"min-h-80 d-flex align-end",attrs:{"xs12":""}},[(_vm.isReservationSelected)?_c('p',{staticClass:"text-blue cursor-pointer margin-a-0",on:{"click":_vm.clearSelectedReservation}},[_vm._v(" Back ")]):_c('v-layout',{staticClass:"align-center",attrs:{"row":""}},[_c('CRInput',{staticClass:"no-grow w-140 margin-r-4",attrs:{"label":"Filter by Date","type":"date","hide-details":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}}),_vm._l((_vm.statuses),function(status){return _c('div',{key:status.key,staticClass:"d-flex border-1 border-solid border-gray-light border-radius-pill padding-y-2 padding-x-4 margin-r-2 margin-t-3 cursor-pointer no-grow",class:status.selected ? 'background-blue text-white' : '',on:{"click":function($event){status.selected = !status.selected}}},[_c('p',{staticClass:"margin-a-0"},[_vm._v(_vm._s(status.name))])])})],2)],1),(!_vm.isReservationSelected && !_vm.loading)?_c('div',{staticClass:"max-h-600",staticStyle:{"overflow-y":"auto"}},_vm._l((_vm.reservations),function(reservation){return _c('v-flex',{key:reservation.reservationId,staticClass:"reservation-card padding-y-2 padding-x-5 border-1 border-solid border-radius-5 cursor-pointer margin-b-2 background-blue-pale",class:{
            hover: _vm.hoverReservationId === reservation.reservationId,
          },attrs:{"xs11":""},on:{"click":function($event){_vm.selectedReservationId = reservation.reservationId},"mouseover":function($event){_vm.hoverReservationId = reservation.reservationId},"mouseleave":function($event){_vm.hoverReservationId = null}}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":""}},[_c('v-flex',[_c('v-layout',{staticClass:"align-center",attrs:{"row":""}},[_c('h3',{staticClass:"font-weight-bold no-grow"},[_vm._v(" "+_vm._s(reservation?.tripName)+" ")]),(reservation?.reservationStatus)?_c('v-chip',{staticClass:"margin-l-2 no-grow text-white",attrs:{"color":_vm.reservationStatusColor(reservation.reservationStatus),"label":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.capitalize(reservation.reservationStatus))+" ")]):_vm._e()],1)],1),_c('v-flex',{staticClass:"no-grow"},[_c('v-menu',{attrs:{"id":`reservation-menu-${reservation.reservationId}`,"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({on:{"click":function($event){$event.stopPropagation();_vm.selectedMenuReservationId = reservation.reservationId}}},on),[_c('CRIcon',{staticClass:"margin-l-2 cursor-pointer",attrs:{"height":"24","width":"24","view-box":"0 0 24 24"}},[_vm._v(" more_vert ")])],1)]}}],null,true),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.selectedMenuReservationId === reservation.reservationId
                  ),expression:"\n                    selectedMenuReservationId === reservation.reservationId\n                  "}]},[_c('v-list-tile',[_c('v-list-tile-title',[_c('p',{staticClass:"text-primary cursor-pointer margin-a-0",on:{"click":function($event){return _vm.openReservationTracking(reservation.reservationId)}}},[_vm._v(" View Reservation Tracking Map ")])])],1),_c('v-list-tile',[_c('v-list-tile-title',[_c('p',{staticClass:"text-primary cursor-pointer margin-a-0",on:{"click":function($event){return _vm.openReservationDetail(reservation.reservationId)}}},[_vm._v(" View Reservation Detail ")])])],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs10":""}},[_c('p',{staticClass:"margin-a-0 text-gray-medium-dark"},[_vm._v("Pickup Location")]),_c('p',{staticClass:"margin-a-0 font-14 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.nextStop(reservation.stops)?.address?.title)+" ")])]),_c('v-flex',{attrs:{"xs2":""}},[_c('p',{staticClass:"margin-a-0 text-gray-medium-dark"},[_vm._v("Time")]),_c('p',{staticClass:"margin-a-0 font-14 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.nextStop(reservation.stops)))+" ")])])],1),(reservation.referrals.length)?_c('p',{staticClass:"margin-a-0 text-gray-medium-dark font-weight-bold padding-t-2"},[_vm._v(" Referrals ")]):_vm._e(),_vm._l((reservation.referrals),function(referral){return _c('v-layout',{key:referral.reservationId,staticClass:"padding-b-2",attrs:{"column":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":""}},[_c('p',{staticClass:"margin-a-0 font-weight-bold font-16"},[_vm._v(" "+_vm._s(referral.managedId)+" ")]),(
                    referral.requiredVehicles >
                    referral.tripAssignments.length
                  )?_c('p',{staticClass:"margin-a-0 text-primary font-weight-bold cursor-pointer padding-l-2",on:{"click":function($event){return _vm.openAssignmentsSidebar(
                      reservation.reservationId,
                      reservation.managedId,
                      referral.reservationId,
                      referral.companyId
                    )}}},[_vm._v(" + Add Assignment ")]):_vm._e()])],1),_vm._l((referral.tripAssignments),function(tripAssignment){return _c('v-layout',{key:tripAssignment.tripAssignmentId,staticClass:"padding-t-2",attrs:{"row":""}},[_c('v-flex',{attrs:{"xs6":""}},[_vm._l((tripAssignment.drivers),function(driver){return _c('v-layout',{key:driver.tripAssignmentId,staticClass:"cursor-pointer",attrs:{"row":""},on:{"click":function($event){return _vm.openAssignmentsSidebar(
                      reservation.reservationId,
                      reservation.managedId,
                      referral.reservationId,
                      referral.companyId
                    )}}},[_c('CRIcon',{attrs:{"color":"green","width":"20","height":"20"}},[_vm._v(" drivers_circle ")]),_c('p',{staticClass:"margin-a-0 font-weight-bold padding-l-2"},[_vm._v(" "+_vm._s(driver.driverName)+" ")])],1)}),(tripAssignment.drivers.length === 0)?_c('v-layout',{staticClass:"cursor-pointer",attrs:{"row":""},on:{"click":function($event){return _vm.openAssignmentsSidebar(
                      reservation.reservationId,
                      reservation.managedId,
                      referral.reservationId,
                      referral.companyId
                    )}}},[_c('CRIcon',{attrs:{"color":"grayMediumDark","width":"20","height":"20"}},[_vm._v(" drivers_circle ")]),_c('p',{staticClass:"margin-a-0 font-weight-bold text-gray-medium-dark padding-l-2"},[_vm._v(" Unassigned ")])],1):_vm._e()],2),(tripAssignment.vehicleLicensePlate)?_c('v-flex',{attrs:{"xs6":""},on:{"click":function($event){return _vm.openAssignmentsSidebar(
                    reservation.reservationId,
                    reservation.managedId,
                    referral.reservationId,
                    referral.companyId
                  )}}},[_c('v-layout',{staticClass:"align-center cursor-pointer",attrs:{"row":""}},[_c('CRIcon',{attrs:{"color":"green","width":"20","height":"20"}},[_vm._v(" vehicles ")]),_c('p',{staticClass:"margin-a-0 font-weight-bold padding-l-2"},[_vm._v(" "+_vm._s(tripAssignment.vehicleName)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"w-8 h-8 border-radius-round margin-l-1",class:_vm.hasRecentTrackingData(tripAssignment.vehicleId)
                            ? 'background-green'
                            : 'background-red'},on))]}}],null,true)},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Vehicle type:")]),_vm._v(" "+_vm._s(_vm.findVehicleTrackingDevice(tripAssignment.vehicleId) ?.vehicleType || 'N/A')+" ")]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("ELD type:")]),_vm._v(" "+_vm._s(_vm.findVehicleTrackingDevice(tripAssignment.vehicleId) ?.eldType || 'N/A')+" ")]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Last report time:")]),_vm._v(" "+_vm._s(_vm.deviceReportTime( _vm.findVehicleTrackingDevice(tripAssignment.vehicleId) ))+" ")]),(!_vm.lastSyncOnly)?_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Device ID:")]),_vm._v(" "+_vm._s(_vm.findVehicleTrackingDevice(tripAssignment.vehicleId) ?.deviceId || 'N/A')+" ")]):_vm._e()])],1)],1):_c('v-flex',{staticClass:"cursor-pointer",attrs:{"xs6":""},on:{"click":function($event){return _vm.openAssignmentsSidebar(
                    reservation.reservationId,
                    reservation.managedId,
                    referral.reservationId,
                    referral.companyId
                  )}}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":""}},[_c('CRIcon',{attrs:{"color":"grayMediumDark","width":"20","height":"20"}},[_vm._v(" vehicles ")]),_c('p',{staticClass:"margin-a-0 font-weight-bold text-gray-medium-dark padding-l-2"},[_vm._v(" Unassigned ")])],1)],1)],1)})],2)})],2)}),1):(!_vm.loading)?_c('ContractTrackingReservationDetail',{attrs:{"reservation-id":_vm.selectedReservationId,"contract-id":_vm.contract.contractId,"vehicles":_vm.filteredVehicles}}):_vm._e()],1),_c('v-flex',{attrs:{"xs8":""}},[_c('CountdownTimer',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],style:(_vm.$vuetify.breakpoint.xs ? 'margin-right: -14px' : ''),attrs:{"time-limit":_vm.standardRefreshInterval,"size":_vm.timerSize},on:{"countdown-finished":_vm.refresh}}),_c('ContractTrackingMap',{ref:"map",attrs:{"id":"map","filtered-vehicles":_vm.filteredVehicles,"stops":_vm.stops,"hover-reservation-id":_vm.hoverReservationId,"refresh-interval":_vm.standardRefreshInterval},on:{"mouseover-vehicle":function($event){_vm.hoverReservationId = $event},"mouseout-vehicle":function($event){_vm.hoverReservationId = null},"click-vehicle":function($event){_vm.selectedReservationId = $event}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }