var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"action-links",attrs:{"justify-end":"","row":""}},[_vm._l((_vm.menu.filter((m) => m.quickAccess)),function(item,idx){return _c('div',{key:idx,staticClass:"action-link-container",style:(item.icon() === 'credit_card' ? 'margin-right: 5px;' : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(action){return [_c('span',_vm._g({staticStyle:{"max-height":"24px"},on:{"click":function($event){$event.stopPropagation();return item.click.apply(null, arguments)}}},action.on),[(item.click)?_c('CRIcon',{key:item.icon() === 'affiliate_management'
                ? `item-${idx}-${item.color()}`
                : `item-${idx}`,staticClass:"cursor-pointer",attrs:{"id":`${_vm.rowProps.index}-table-actions-icon-toggle-activate-${idx}`,"color":(item.color && item.color()) || 'primary',"view-box":item.viewBox,"width":24,"height":24,"file-name":_vm.snakeToPascal(item.icon())}}):_vm._e(),(item.path)?_c('router-link',{staticClass:"action-link",attrs:{"id":`${_vm.rowProps.index}-table-actions-link-icon-path-${idx}`,"target":"_blank","to":{ path: item.path() }}},[_c('CRIcon',{attrs:{"color":(item.color && item.color()) || 'primary',"view-box":item.viewBox}},[_vm._v(" "+_vm._s(item.icon())+" ")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title()))])])],1)}),_c('div',[_c('v-menu',{attrs:{"bottom":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: { click } }){return [_c('CRIcon',{staticClass:"cursor-pointer",staticStyle:{"max-height":"24px"},attrs:{"id":`${_vm.rowProps.index}-table-more-actions-menu`,"color":"primary","view-box":"0 0 24 24"},nativeOn:{"click":function($event){return click.apply(null, arguments)}}},[_vm._v(" more_vert ")])]}}])},[_c('v-list',[_vm._l((_vm.menu.filter((m) =>
            m.condition ? m.condition() : true
          )),function(item,i){return [(item.condition ? item.condition() : true)?_c('v-list-tile',{key:`${_vm.rowProps.index}-table-more-actions-menu-item-${i}`,attrs:{"id":`${_vm.rowProps.index}-table-more-actions-menu-item-${
              item.title ? _vm.toKebab(item.title()) : i
            }`},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-list-tile-title',[_c('div',{staticClass:"action-link"},[(item.icon)?_c('CRIcon',{attrs:{"color":(item.color && item.color()) || 'primary',"view-box":item.viewBox}},[_vm._v(" "+_vm._s(item.icon())+" ")]):_vm._e(),_c('span',{staticClass:"action-text"},[_vm._v(_vm._s(item.title()))])],1)])],1):_vm._e()]})],2)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }