<template>
  <v-layout
    class="background-blue-pale border-radius-5 margin-r-5 padding-y-2 padding-x-5"
    column
  >
    <v-flex xs12>
      <v-layout class="align-center" row>
        <v-flex>
          <v-layout class="align-center" row>
            <h3 class="font-weight-bold no-grow">
              {{ reservation?.tripName }}
            </h3>
            <v-chip
              v-if="reservation?.reservationStatus"
              :color="reservationStatusColor(reservation.reservationStatus)"
              label
              disabled
              class="margin-l-2 no-grow text-white"
            >
              {{ capitalize(reservation.reservationStatus) }}
            </v-chip>
          </v-layout>
        </v-flex>
        <v-flex class="no-grow">
          <v-menu
            :id="`reservation-detail-menu-${reservationId}`"
            bottom
            transition="slide-y-transition"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <CRIcon
                  height="24"
                  width="24"
                  view-box="0 0 24 24"
                  class="margin-l-2 cursor-pointer"
                >
                  more_vert
                </CRIcon>
              </div>
            </template>
            <v-list>
              <v-list-tile>
                <v-list-tile-title>
                  <p
                    class="text-primary cursor-pointer margin-a-0"
                    @click="openReservationTracking(reservationId)"
                  >
                    View Reservation Tracking Map
                  </p>
                </v-list-tile-title>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-title>
                  <p
                    class="text-primary cursor-pointer margin-a-0"
                    @click="openReservationDetail(reservationId)"
                  >
                    View Reservation Detail
                  </p>
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </v-flex>
    <p class="margin-a-0 text-gray-medium-dark font-weight-bold padding-t-2">
      Referrals
    </p>
    <v-layout
      v-for="referral in reservation?.referrals"
      :key="referral.reservationId"
      class="padding-b-2"
      column
    >
      <v-flex xs12>
        <v-layout class="align-center" row>
          <p class="margin-a-0 font-weight-bold font-16">
            {{ referral.managedId }}
          </p>
          <p
            v-if="referral.requiredVehicles > referral.tripAssignments.length"
            class="margin-a-0 text-primary font-weight-bold cursor-pointer padding-l-2"
            @click="
              openAssignmentsSidebar(referral.reservationId, referral.companyId)
            "
          >
            + Add Assignment
          </p>
        </v-layout>
      </v-flex>
      <v-layout
        v-for="tripAssignment in referral.tripAssignments"
        :key="tripAssignment.tripAssignmentId"
        class="padding-t-2"
        row
      >
        <v-flex xs6>
          <v-layout
            v-for="driver in tripAssignment.drivers"
            :key="driver.tripAssignmentId"
            class="cursor-pointer"
            @click="
              openAssignmentsSidebar(referral.reservationId, referral.companyId)
            "
            row
          >
            <CRIcon color="green" width="20" height="20">drivers_circle</CRIcon>
            <p class="margin-a-0 font-weight-bold padding-l-2">
              {{ driver.driverName }}
            </p>
          </v-layout>
          <v-layout
            v-if="tripAssignment.drivers.length === 0"
            class="cursor-pointer"
            @click="
              openAssignmentsSidebar(referral.reservationId, referral.companyId)
            "
            row
          >
            <CRIcon color="grayMediumDark" width="20" height="20">
              drivers_circle
            </CRIcon>
            <p
              class="margin-a-0 font-weight-bold text-gray-medium-dark padding-l-2"
            >
              Unassigned
            </p>
          </v-layout>
        </v-flex>
        <v-flex
          v-if="tripAssignment.vehicleLicensePlate"
          @click="
            openAssignmentsSidebar(referral.reservationId, referral.companyId)
          "
          xs6
        >
          <v-layout class="align-center cursor-pointer" row>
            <CRIcon color="green" width="20" height="20">vehicles</CRIcon>
            <p class="margin-a-0 font-weight-bold padding-l-2">
              {{ tripAssignment.vehicleName }}
            </p>
            <v-tooltip top>
              <template #activator="{ on }">
                <div
                  class="w-8 h-8 border-radius-round margin-l-1"
                  :class="
                    tripAssignment.trackedStatus === 'tracked'
                      ? 'background-green'
                      : 'background-red'
                  "
                  v-on="on"
                ></div>
              </template>
              <div>
                <span class="font-weight-bold">Vehicle type:</span>
                {{
                  findVehicleTrackingDevice(tripAssignment.vehicleId)
                    ?.vehicleType || 'N/A'
                }}
              </div>
              <div>
                <span class="font-weight-bold">ELD type:</span>
                {{
                  findVehicleTrackingDevice(tripAssignment.vehicleId)
                    ?.eldType || 'N/A'
                }}
              </div>
              <div>
                <span class="font-weight-bold">Last report time:</span>
                {{
                  deviceReportTime(
                    findVehicleTrackingDevice(tripAssignment.vehicleId)
                  )
                }}
              </div>
              <div v-if="!lastSyncOnly">
                <span class="font-weight-bold">Device ID:</span>
                {{
                  findVehicleTrackingDevice(tripAssignment.vehicleId)
                    ?.deviceId || 'N/A'
                }}
              </div>
            </v-tooltip>
          </v-layout>
        </v-flex>
        <v-flex
          v-else
          class="cursor-pointer"
          @click="
            openAssignmentsSidebar(referral.reservationId, referral.companyId)
          "
          xs6
        >
          <v-layout class="align-center" row>
            <CRIcon color="grayMediumDark" width="20" height="20">
              vehicles
            </CRIcon>
            <p
              class="margin-a-0 font-weight-bold text-gray-medium-dark padding-l-2"
            >
              Unassigned
            </p>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout class="padding-t-4" column>
      <v-flex class="padding-b-1" xs12>
        <v-layout row>
          <v-flex xs10>
            <p class="margin-a-0 text-gray-medium-dark font-weight-bold">
              Itinerary
            </p>
          </v-flex>
          <v-flex xs2>
            <p class="margin-a-0 text-gray-medium-dark">Time</p>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout
        v-for="stop in reservation?.stops"
        :key="stop.stopId"
        class="padding-l-2"
        column
      >
        <v-flex xs12>
          <v-layout row>
            <v-flex xs10>
              <p class="margin-a-0 font-weight-bold">
                {{ stop.address.title }}
              </p>
            </v-flex>
            <v-flex xs2>
              <p class="margin-a-0 font-weight-bold">
                {{ formatTime(stop) }}
              </p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs10>
          <p class="margin-a-0 text-gray-medium-dark">
            {{ buildAddressName(stop?.address) }}
          </p>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import { capitalize } from '@/utils/string'
import { DateTime } from 'luxon'
import { buildAddressName } from '@/utils/address'
import { phoneFormatFilter } from '@/utils/phone'
import { reservationById } from '@/services/reservations'
import { mapActions } from 'vuex'
import drivers from '@/services/drivers'
import { timeAgo } from '@/utils/time'
import { getReservationDetailForContractTracking, getTripAssignmentsForReservation } from '@/services/reservations'

const RECENT_TRACKING_MINUTES = 5

export default {
  props: {
    reservationId: {
      type: Number,
      required: true,
    },
    contractId: {
      type: Number,
      required: true,
    },
    device: {
      type: Object,
      default: null,
    },
    vehicles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loadingAssignments: false,
      reservation: null,
    }
  },
  async mounted() {
    try {
      const resData = await getReservationDetailForContractTracking(
        this.contractId,
        this.reservationId
      )
      this.reservation = resData.data
    } catch (error) {
      this.showAlert({
        type: 'error',
        message: "There was an error retrieving the reservation. Please try again.",
      })
      console.warn(error)
    }
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    capitalize,
    buildAddressName,
    phoneFormatFilter,
    // TODO: Clean up the dispatch reservation sidebar and make a new endpoint that surfaces all the information needed so it does not have to
    // be called before-hand
    async openAssignmentsSidebar(referralId, companyId) {
      this.loadingAssignments = true
      try {
        const component = () => import('./DispatchReservationSidebar.vue')
        const res = await reservationById(this.reservation.managedId)
        const reservation = res.data
        const referral = reservation.referredTo.find(
          (referral) => referral.reservationId === referralId
        )
        const driverData = await drivers.getAllDriversForCompany({
          page: 1,
          pageSize: -1,
          companyId: companyId,
        })
        const driverList = driverData?.data?.resultList
        const vehicleData = await this.$store.dispatch(
          'vehicles/getAllVehiclesForCompany',
          {
            page: 1,
            pageSize: -1,
            companyId: companyId,
          }
        )
        const vehicleList = vehicleData?.data?.resultList

        const allDrivers = driverList.map((driver) => ({
          ...driver,
          name: `${driver.firstName} ${driver.lastName}`,
        }))
        const allVehicles = vehicleList.map((vehicle) => ({
          ...vehicle,
          name: `${vehicle.vehicleName} ${vehicle.vehicleModel}`,
        }))
        this.loadingAssignments = false

        this.$store.dispatch('app/openSidebarDialog', {
          component,
          data: {
            title: 'Add Assignments',
            parentReservationId: this.reservationId,
            reservationId: referral.reservationId,
            managedId: referral.managedId,
            referral: referral,
            isAssignmentsOnly: true,
            vehicles: allVehicles,
            drivers: allDrivers,
            garages: [],
            companyId: companyId,
          },
          persistent: true,
        })
      } catch (e) {
        this.loadingAssignments = false
        console.error(e)
        this.showAlert({
          type: 'error',
          message: 'Unable to load reservation assignments.',
        })
      }
    },
    async openReservationTracking(reservationId) {
      const reservation = await this.$store.dispatch(
        'reservations/fetchReservationById',
        {
          reservationId: reservationId,
          companyId: this.currentUser?.companyId,
          viewRejectedReferrals: false,
          force: true,
        }
      )

      if (!reservation) {
        return
      }

      let referredProviders = reservation?.referredTo?.filter(
        (ref) => ref.referralStatus === 'accepted'
      )
      referredProviders = referredProviders?.map((provider) => {
        let computedName = provider.companyName
        if (
          referredProviders.filter((p) => p.companyId === provider.companyId)
            .length > 1
        ) {
          computedName += ` - ${provider.managedId}`
        }
        return { computedName, ...provider }
      })

      const currentProvider = reservation?.referredTo?.[0]
      let tripVehicleGroups = []
      if (currentProvider?.tripVehicleGroups) {
        tripVehicleGroups = currentProvider.tripVehicleGroups
      } else {
        tripVehicleGroups = reservation.tripVehicleGroups
      }

      const trackingStatusResult = await this.$store.dispatch(
        'reservations/getReservationTrackingStatuses',
        [reservationId]
      )
      const trackingReservationStatus =
        trackingStatusResult?.data?.trackingStatuses[0]?.reservationStatus
      const trackingAllocation =
        trackingStatusResult?.data?.trackingStatuses[0]?.trackingAllocation

      const itineraryItems = reservation.stops?.map((stop, index) => {
        const {
          stopId,
          address,
          notes,
          stopNotes,
          risks,
          flightInformation,
          tripVehicleGroupId,
        } = stop
        const newStop = {
          stopNumber: index + 1,
          stopId,
          address,
          timeZone: address.timeZone,
          notes,
          stopNotes,
          risks,
          flightInformation,
          tripVehicleGroupId,
        }
        if (stop.dropoffDatetime && stop.pickupDatetime) {
          newStop.type = 'Drop-off\nPickup'
        } else if (stop.dropoffDatetime) {
          newStop.type = 'Drop-off'
        } else if (stop.pickupDatetime) {
          newStop.type = 'Pickup'
        } else {
          newStop.type = '--'
        }
        newStop.pickupDatetime = stop.pickupDatetime
        newStop.dropoffDatetime = stop.dropoffDatetime
        return newStop
      })

      const vehicleAssignmentsData = await getTripAssignmentsForReservation({
        reservationIds: [reservation.reservationId],
      })
      const tripAssignments = vehicleAssignmentsData.data.vehicleAssignments

      const component = () => import('@/components/ReservationMapSidebar.vue')
      this.$store.dispatch('app/openDialog', {
        data: {
          active: true,
          isLoading: false,
          reservationId: reservation.reservationId,
          managedId: reservation.managedId,
          reservationStatus: reservation.reservationStatus,
          journeys: reservation.journeys,
          startDate: reservation.startDate,
          tripVehicleGroups: tripVehicleGroups,
          stops: reservation.stops,
          referredProviders,
          fullTripVehicleGroups: reservation.tripVehicleGroups,
          trackingAllocation,
          trackingReservationStatus,
          tripAssignments,
          itineraryItems,
          linkToReservationDetail: true,
        },
        component,
      })
    },
    openReservationDetail(reservationId) {
      const routeData = this.$router.resolve({
        name: 'reservation-detail',
        params: {
          id: reservationId,
          ignoreCache: true,
        },
      })
      window.open(routeData.href, '_blank')
    },
    findVehicleTrackingDevice(vehicleId) {
      const foundDevice = this.vehicles.find(
        (vehicle) => vehicle.vehicleId == vehicleId
      )
      return foundDevice || null
    },
    hasRecentTrackingData(vehicleId) {
      const foundDevice = this.vehicles.find(
        (position) => position.vehicleId == vehicleId
      )
      if (!foundDevice?.lastTransmitted) {
        return false
      }

      const lastSyncDateTime = DateTime.fromISO(foundDevice.lastTransmitted)
      const trackingDataInterval = Interval.fromDateTimes(
        lastSyncDateTime,
        DateTime.utc()
      )

      return trackingDataInterval.length('minutes') <= RECENT_TRACKING_MINUTES
    },
    reservationStatusColor(status) {
      switch (status) {
        case 'started':
          return 'green'
        case 'upcoming':
          return 'blue'
        case 'finished':
          return 'gray-dark'
        default:
          return 'gray-dark'
      }
    },
    formatTime(stop) {
      const time = stop?.pickupDateTime || stop?.dropoffDateTime
      if (!time) {
        return ''
      }
      return DateTime.fromISO(time, { zone: stop?.address?.timeZone }).toFormat(
        'hh:mm a'
      )
    },
    deviceReportTime(device) {
      if (!device) {
        return ''
      }
      if (
        DateTime.fromISO(device.lastTransmitted) <
        DateTime.local().minus({ days: 1 })
      ) {
        return DateTime.fromISO(device.lastTransmitted).toFormat('yyyy-MM-dd')
      }
      return timeAgo(device.lastTransmitted)
    },
  },
}
</script>
