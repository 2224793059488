<template>
  <div id="contract-payments" class="contract-payments">
    <v-layout row justify-start full-width align-center>
      <v-flex shrink>
        <v-btn-toggle
          :id="`${id}-contract-payments-button-toggle-data-type-changed-broker`"
          v-model="paymentDataType"
          mandatory
          class="type-toggler"
          @change="paymentDataTypeChangedByBroker"
        >
          <v-btn
            :id="`${id}-contract-payments-button-toggle-data-type-changed-client`"
            depressed
            active-class="primary"
            value="client"
          >
            Client
          </v-btn>
          <v-btn
            :id="`${id}-contract-payments-button-display-provider-list`"
            depressed
            :disabled="!providerList || !providerList[0]"
            active-class="primary"
            value="provider"
          >
            Provider
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-flex v-if="paymentDataType === 'provider'">
        <v-menu ref="menuRef">
          <template #activator="{ on }">
            <v-btn
              :id="`${id}-contract-payments-button-provider-display-list`"
              class="btn-secondaryaction action-button"
              v-on="on"
            >
              {{ currentProvider.managedId }} -
              {{ currentProvider.name }} &nbsp;&nbsp;
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(provider, index) in providerList">
              <v-list-tile :key="index" @click="setProvider(provider)">
                <v-list-tile-title>
                  {{ provider.managedId }} - {{ provider.name }}
                </v-list-tile-title>
              </v-list-tile>
            </template>
          </v-list>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout column class="summary-container">
      <v-data-table
        :headers="headers"
        :items="transactions"
        :hide-actions="true"
        :align="'end'"
      >
        <template #items="props">
          <td align="center">
            {{ props.item.createdOn }}
          </td>
          <td align="left">
            {{ props.item.description }}
          </td>
          <td width="400px" align="left">
            {{ props.item.notes }}
          </td>
          <td class="payment pr-7">{{ props.item.payment }}</td>
        </template>
        <template #footer>
          <td colspan="3" class="pdl-40" align="start">Totals</td>
          <td align="end" class="pl-0 pr-7">
            {{ currencyFilter(displayedPayments) }}
          </td>
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import transaction from '@/services/transaction'
import { filter } from '@/utils/filter'

import { DateTime } from 'luxon'
import { mapActions } from 'vuex'

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      paymentDataType: null,
      currencyFilter,
      currentProvider: null,
      currentProviderBalance: 0,
      currentProviderAmount: 0,
      actionsDialog: false,
      dialogType: '',
      displayedPayments: 0,
      actionReservationRow: {},
      headers: [
        {
          text: 'Date',
          value: 'createdOn',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Notes',
          value: 'flattenedNotes',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Payment',
          value: 'payment',
          sortable: false,
          align: 'end',
        },
      ],
      transactions: [],
      reservations: [],
      contractReservationIds: [],
      providerList: [],
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    await this.getReservations()
    this.setProvider(this.providerList?.[0])
    this.getTransactions()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    async getReservations() {
      const filterObject = {
        column: {
          _t_id: 'contract_id_filter_1',
          filterType: 'eq',
          prop: 'trip.quote.contractId',
        },
        value: this.contract.contractId,
      }
      const contractFilter = filter()
      const parentFilter = contractFilter.createParent('and')
      contractFilter.add(parentFilter, filterObject)
      const params = {
        page: 1,
        pageSize: -1,
        filters: contractFilter.asQueryParams(),
      }
      const reservationResponse = await this.$store.dispatch(
        'reservations/reservationsTableView',
        params
      )

      this.reservations = reservationResponse.data.resultList
      this.reservations.forEach((reservation) => {
        this.contractReservationIds.push(reservation.reservationId)
        reservation.referredTo.forEach((provider) => {
          this.providerList.push(provider)
        })
      })
    },
    async getTransactions() {
      let transactionsList = []
      this.displayedPayments = 0

      let reservationIdsToGetTransactions
      if (this.paymentDataType === 'client') {
        reservationIdsToGetTransactions = this.contractReservationIds
      } else {
        reservationIdsToGetTransactions = [this.currentProvider.reservationId]
      }

      reservationIdsToGetTransactions.forEach(async (reservation) => {
        const data = await transaction
          .byReservationId(reservation)
          .then((data) => data.data)
          .catch()
        data.forEach((t) => {
          const result = {}
          result.createdOn = `${DateTime.fromISO(t.createdOn).toLocaleString(
            DateTime.DATE_SHORT
          )} - ${DateTime.fromISO(t.createdOn).toLocaleString(
            DateTime.TIME_SIMPLE
          )}`
          result.description = t.description
          result.notes = t.notes
          result.charge = t.charge ? this.currencyFilter(t.charge) : null
          result.payment = t.payment ? this.currencyFilter(t.payment) : null
          result.amount = this.currencyFilter(t.amount)
          result.balance = this.currencyFilter(t.balance)

          if (result.payment !== null) {
            transactionsList.push(result)
          }
        })

        this.displayedPayments += data.reduce((acc, item) => {
          acc += item.payment || 0
          return acc
        }, 0)
      })
      this.transactions = transactionsList
    },
    setProvider(provider) {
      this.currentProvider = provider
      if (this.paymentDataType === 'provider') {
        this.getTransactions()
      }
    },
    paymentDataTypeChangedByBroker() {
      this.getTransactions()
    },
  },
}
</script>

<style lang="scss" scoped>
.contract-payments {
  padding: 0 8px 8px;

  .type-toggler {
    margin-right: 1em;
    border-radius: 5px;
    box-shadow: none;
    margin-bottom: 8px;

    .v-btn {
      height: unset;
      padding: 15px 40px;

      &.primary {
        color: $white;
      }
    }
  }

  .action-button {
    padding: 5px 15px;
  }

  .summary-container {
    // margin: 50px 75px;
    .container {
      padding-left: 0;
    }

    .summary-column {
      font-size: 16px;
      background-color: $blue-pale;

      .item-row {
        padding: 15px 25px;

        .amount {
          color: $primary;
          text-align: right;
        }
      }

      .balance-row {
        padding: 15px 25px;
        font-size: 14px;
        color: $white;
        background-color: $gray-dark;

        .flex:last-of-type {
          text-align: right;
        }
      }
    }
  }
}

.pdl-40 {
  padding-left: 40px !important;
}

::v-deep .v-datatable tbody td.payment {
  padding-right: 24px !important;
}

::v-deep table {
  margin-top: 0;
  border: 1px solid $gray-light;

  &.v-table thead tr:first-child th {
    padding: 17px 10px !important;
    font-size: 14px !important;
  }

  &.v-table thead tr th.balance-header {
    padding-right: 15px !important;
  }

  tfoot {
    height: 45px;
    font-size: 20px;
    line-height: 2;
    color: $white;
    text-align: center;
    background-color: $gray;
  }
}
</style>
