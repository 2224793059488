<template>
  <div>
    <div
      class="recenter-button"
      :class="{
        disabled: !customPosition,
        mobile: $vuetify.breakpoint.xs,
      }"
      @click="handleRecenter"
    >
      <CRIcon width="35" height="35" :style="{ margin: '10px' }">
        crosshairs_gps
      </CRIcon>
    </div>
    <GmapMap
      ref="gMap"
      class="map"
      :zoom="mapConfig.zoom"
      :center="mapConfig.center"
      :options="mapConfig.options"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { timeAgo } from '@/utils/time'
import { toCamelCase } from '@/utils/string'
import { enterpriseMapStyles, mapStyles } from '@/components/mapStyles.js'
import pinNumbers from '@/utils/pinNumbers'
import { gmapApi } from 'vue2-google-maps'

export default {
  props: {
    filteredVehicles: {
      type: Array,
      default: () => [],
    },
    stops: {
      type: Array,
      default: () => [],
    },
    hoverReservationId: {
      type: Number,
      default: 0,
    },
    refreshInterval: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      directionsService: null,
      map: null,
      bounds: null,
      vehicleMarkers: [],
      stopMarkers: [],
      highlightMarkers: [],
      outlinedHighlightMarkers: [],
      infoWindows: [],
      customPosition: false,
      mapConfig: {
        center: { lat: 37.09, lng: -95.71 },
        zoom: 18,
        options: {
          clickableIcons: false,
          streetViewControl: false,
          fullScreenControl: true,
          mapTypeControl: false,
          styles: enterpriseMapStyles,
          fullscreenControlOptions: {
            position: -1,
          },
          gestureHandling: 'greedy',
          minZoom: 2,
          maxZoom: 20,
        },
      },
    }
  },
  computed: {
    google: gmapApi,
  },
  watch: {
    filteredVehicles: {
      deep: true,
      async handler() {
        await this.clearMap()
        await this.drawStops()
        await this.drawVehicles()
        if (!this.customPosition) {
          this.handleRecenter()
        }
      },
    },
    stops: {
      deep: true,
      handler() {
        this.customPosition = false
      },
    },
    hoverReservationId(newValue) {
      if (!!newValue) {
        const foundVehicles = this.filteredVehicles.filter(
          (fv) => fv.reservation.parentReservationId === newValue
        )
        for (const vehicle of foundVehicles) {
          this.drawHighlight(vehicle)
        }
      } else {
        this.clearHighlights()
      }
    },
  },
  async mounted() {
    this.mapConfig.options.styles = mapStyles
    this.map = await this.$refs.gMap.$mapPromise
    this.bounds = new google.maps.LatLngBounds()
    this.initializeDirectionsService()
    setTimeout(() => {
      this.map.addListener('zoom_changed', () => this.handleCenterChanged())
      this.map.addListener('drag', () => this.handleCenterChanged())
    }, 1000)
  },
  methods: {
    clearMap() {
      this.clearInfoWindows()
      this.clearVehicles()
      this.clearStops()
      this.clearHighlights()
    },
    clearVehicles() {
      for (const marker of this.vehicleMarkers) {
        marker.setMap(null)
      }
      this.vehicleMarkers = []
    },
    clearStops() {
      for (const marker of this.stopMarkers) {
        marker.setMap(null)
      }
      this.stopMarkers = []
    },
    clearInfoWindows() {
      for (const infoWindow of this.infoWindows) {
        infoWindow.close()
      }
      for (const infoWindow of this.infoWindows) {
        infoWindow.setMap(null)
      }
      this.infoWindows = []
    },
    clearHighlights() {
      for (const marker of this.highlightMarkers) {
        marker.setMap(null)
      }
      this.highlightMarkers = []
    },
    initializeDirectionsService() {
      this.directionsService = (() => {
        const ds = new this.google.maps.DirectionsService()
        let counter = 1
        return (dOpts, dc) => {
          counter++
          setTimeout(() => {
            ds.route(dOpts, dc)
            counter--
          }, 80 * counter)
        }
      })()
    },
    drawVehicles() {
      for (const vehicle of this.filteredVehicles) {
        const infoWindow = this.makeVehicleInfoWindow(vehicle)
        const permanentInfoWindow = this.makeVehiclePermanentInfoWindow(vehicle)
        const icon = this.makeVehicleIcon(vehicle.vehicleType, vehicle.heading)
        const marker = this.makeVehicleMarker(
          vehicle,
          this.map,
          vehicle.lat,
          vehicle.lng,
          icon,
          infoWindow,
          permanentInfoWindow
        )
        marker.trak4DeviceId = vehicle.trak4DeviceId
        if (!vehicle.active) {
          marker.setOpacity(0.35)
        }
      }
    },
    drawStops() {
      for (const stop of this.stops) {
        const address = stop.address
        const infoWindow = this.makeStopInfoWindow(address)
        const marker = this.makeStopMarker(
          '#ea7721',
          address.lat,
          address.lng,
          infoWindow,
          stop.markerLabel
        )
        marker.setMap(this.map)
      }
    },
    drawHighlight(vehicle, outlined = false) {
      const marker = this.makeHighlightMarker(vehicle, outlined)
      marker.setMap(this.map)
    },
    makeStopMarker(color, latitude, longitude, infoWindow, number) {
      const icon = {
        path:
          'M21,0.2c-11.6,0-21,9.4-21,21C0,27.4,2.7,33,7.1,36.9L21,50.8l13.9-13.9C39.3,33,42,27.4,42,21.2 C42,9.6,32.6,0.2,21,0.2z',
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 0.75,
        anchor: new this.google.maps.Point(21, 50.8),
      }
      const pinNumber = {
        path: pinNumbers[number],
        fillColor: '#000000',
        fillOpacity: 0.35,
        strokeWeight: 0,
        scale: 0.75,
        anchor: new this.google.maps.Point(21, 49.4),
      }

      const marker = this.makeMarker(
        this.map,
        latitude,
        longitude,
        icon,
        infoWindow
      )
      marker.setZIndex((number - 1) * 2)
      const markerDot = this.makeMarker(
        this.map,
        latitude,
        longitude,
        pinNumber,
        null
      )
      markerDot.setZIndex((number - 1) * 2 + 1)
      this.stopMarkers.push(marker)
      this.stopMarkers.push(markerDot)
      return marker
    },
    makeHighlightMarker(vehicle, outlined) {
      const vehicleType = this.getIconVehicleType(vehicle?.vehicleType)
      const anchorPointMap = {
        charterBus: [50, 68],
        miniBus: [50, 66],
        sprinter: [50, 62],
      }
      const icon = {
        path: 'M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0',
        fillColor: vehicle.color,
        fillOpacity: outlined ? 0 : 0.25,
        strokeWeight: outlined ? 2 : 0,
        strokeColor: vehicle.color,
        rotation: 0,
        scale: 2,
        anchor: new this.google.maps.Point(...anchorPointMap[vehicleType]),
      }
      const marker = this.makeMarker(this.map, vehicle.lat, vehicle.lng, icon)
      if (outlined) {
        marker.setZIndex(5)
        this.outlinedHighlightMarkers.push(marker)
      } else {
        marker.setZIndex(0)
        this.highlightMarkers.push(marker)
      }
      return marker
    },
    makeVehicleMarker(
      vehicle,
      map,
      latitude,
      longitude,
      icon,
      infoWindow,
      permanentInfoWindow
    ) {
      const vehicleMarker = new this.google.maps.Marker({
        map: map,
        icon,
        position: new this.google.maps.LatLng(latitude, longitude),
      })
      if (permanentInfoWindow) {
        const permanentInfoWindowOptions = {
          map: map,
          shouldFocus: false,
        }
        permanentInfoWindow.open(permanentInfoWindowOptions, vehicleMarker)
      }
      if (infoWindow) {
        vehicleMarker.addListener('mouseover', () => {
          infoWindow.open(map, vehicleMarker)
          if (permanentInfoWindow) {
            permanentInfoWindow.close(map, vehicleMarker)
          }
          this.$emit(
            'mouseover-vehicle',
            vehicle.reservation.parentReservationId
          )
        })
        vehicleMarker.addListener('mouseout', () => {
          infoWindow.close(map, vehicleMarker)
          if (permanentInfoWindow) {
            permanentInfoWindow.open(map, vehicleMarker)
          }
          this.$emit('mouseout-vehicle')
        })
        vehicleMarker.addListener('click', () => {
          this.$emit('click-vehicle', vehicle.reservation.parentReservationId)
        })
      }
      vehicleMarker.setZIndex(99)
      this.vehicleMarkers.push(vehicleMarker)
      return vehicleMarker
    },
    makeMarker(
      map,
      latitude,
      longitude,
      icon,
      infoWindow,
      permanentInfoWindow
    ) {
      const marker = new this.google.maps.Marker({
        map: map,
        icon,
        position: new this.google.maps.LatLng(latitude, longitude),
      })
      if (infoWindow) {
        marker.addListener('mouseover', () => {
          infoWindow.open(map, marker)
        })
        marker.addListener('mouseout', () => {
          infoWindow.close(map, marker)
        })
      }
      return marker
    },
    makeVehicleIcon(vehicleType, heading) {
      vehicleType = this.getIconVehicleType(vehicleType)
      const url = require(`@/assets/images/busIcons/${heading}.svg`)
      let size = null
      if (vehicleType === 'charterBus') {
        size = [65, 65]
      } else if (vehicleType === 'sprinter') {
        size = [45, 45]
      } else if (vehicleType === 'miniBus') {
        size = [57, 57]
      }
      return { url, scaledSize: new google.maps.Size(...size) }
    },
    getIconVehicleType(vehicleType) {
      if (vehicleType) {
        vehicleType = toCamelCase(vehicleType)
      }
      if (!['charterBus', 'sprinter', 'miniBus'].includes(vehicleType)) {
        vehicleType = 'charterBus'
      }
      return vehicleType
    },
    makeStopInfoWindow(address) {
      const addressTitle = address.title
      let addressString = ''
      if (address.street1 && address.street1 !== ' ') {
        addressString = `${address.street1}, `
      }
      if (address.city) {
        addressString = `${addressString}${address.city}, `
      }
      if (address.state) {
        addressString = `${addressString}${address.state}`
      }

      const infowindow = `<div style="text-align: center;"> <b>${
        addressTitle || address.name || addressString
      }</b><br/>${addressTitle ? address.name || addressString : ''}</div>`
      const infoWindowObj = new this.google.maps.InfoWindow({
        content: infowindow,
      })
      this.infoWindows.push(infoWindowObj)
      return infoWindowObj
    },
    makeVehicleInfoWindow(vehicle) {
      const timestamp = this.formatTimestamp(vehicle.receivedDate)
      const lastTransmitted = timeAgo(vehicle.lastTransmitted)
      const infowindow = `
      <table class="padding-t-4">
          <tr>
            <td colspan=2>${vehicle.vehicleName}</td>
          </tr>
          <tr>
            <td colspan=2><hr/></td>
          </tr>
          ${`<tr>
              <td>Reservation ID:</td>
              <td>${vehicle.reservationId} ${
            vehicle.reservation.tripName
              ? `(${vehicle.reservation.tripName})`
              : ''
          }</td>
            </tr>`}
          ${`<tr>
              <td>Operator:</td>
              <td>${vehicle.operatorName}</td>
            </tr>
            <tr>
              <td>Transmitted:</td>
              <td>${lastTransmitted}</td>
            </tr>
            <tr>
              <td>Timestamp:</td>
              <td>${timestamp}</td>
            </tr>
          `}
          <tr>
            <td>Vehicle Type:</td>
            <td>${vehicle.vehicleType}</td>
          </tr>
          <tr>
            <td>Traveling:</td>
            <td>${vehicle.gpsSpeed || 0} mph ${vehicle.direction}</td>
          </tr>
          ${
            vehicle.isADACompliant
              ? `<tr>
              <td>ADA Compliant</td>
            </tr>`
              : ''
          }
        </table>
        `
      const infoWindowObj = new this.google.maps.InfoWindow({
        content: infowindow,
      })
      infoWindowObj.setZIndex(100)
      this.infoWindows.push(infoWindowObj)
      return infoWindowObj
    },
    makeVehiclePermanentInfoWindow(vehicle) {
      const infowindow = `
      <div class="permanent padding-a-2">
        <p class="text-white margin-a-0">${vehicle.vehicleName}</p>
      </div>
      `
      const infoWindowObj = new this.google.maps.InfoWindow({
        content: infowindow,
        maxWidth: 200,
        disableAutoPan: true,
      })
      infoWindowObj.setZIndex(99)
      this.infoWindows.push(infoWindowObj)
      return infoWindowObj
    },
    handleCenterChanged() {
      this.customPosition = true
    },
    handleRecenter() {
      if (this.stopMarkers?.length || this.vehicleMarkers?.length) {
        this.fitItemsInBounds()
      } else {
        const defaultCenter = new google.maps.LatLng(
          this.mapConfig.center.lat,
          this.mapConfig.center.lng
        )
        this.map.setCenter(defaultCenter)
      }
      setTimeout(() => {
        this.customPosition = false
      }, 0)
    },
    fitItemsInBounds() {
      const bounds = new google.maps.LatLngBounds()
      for (const marker of this.stopMarkers) {
        bounds.extend(marker.getPosition())
      }
      for (const marker of this.vehicleMarkers) {
        bounds.extend(marker.getPosition())
      }
      this.bounds.union(bounds)
      this.map.fitBounds(this.bounds)
      this.customPosition = false
    },
    formatTimestamp(timestamp) {
      const datetime = DateTime.fromISO(timestamp)
      return `${datetime.toLocaleString(
        DateTime.DATE_SHORT
      )} • ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`
    },
  },
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 600px;
  z-index: 0;
}

.recenter-button {
  z-index: 3;
  position: relative;
  top: 48px;
  background: $white;
  border: 1px solid $border-gray !important;
  width: 48px;
  height: 48px;
  color: $primary !important;
  &.disabled {
    color: $border-gray !important;
    cursor: default !important;
  }
  &.public {
    margin-top: 68px;
    &.mobile {
      margin-top: 58px;
    }
  }
}

::v-deep button.gm-ui-hover-effect {
  display: none !important;
}

::v-deep .gm-style-iw-c:has(> .gm-style-iw-d > div > .permanent) {
  padding: 0;
  opacity: 0.75;
  background: #3e4351;
  box-shadow: none;
}

::v-deep .gm-style-iw-d:has(> div > .permanent) {
  overflow: hidden !important;
}

::v-deep .gm-style-iw-chr > .gm-style-iw-ch {
  padding: 0px;
}

::v-deep
  .gm-style-iw-c:has(> .gm-style-iw-d > div > .permanent)
  + .gm-style-iw-tc::after {
  opacity: 0.75;
  background: #3e4351;
  top: 0px;
}

#markerLayer img {
  animation: pulse 0.5s infinite alternate;
  -webkit-animation: pulse 0.5s infinite alternate;
  transform-origin: center;
  -webkit-transform-origin: center;
}

@media only screen and (max-width: 599px) {
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    display: none !important;
  }
}
</style>
