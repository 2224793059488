<template>
  <CRSelect
    v-if="menuItems.length"
    id="contract-action-menu"
    v-model="selectedMenuItem"
    :items="menuItems"
    class="contract-action-menu"
    outline
    flat
    solo
    color="primary"
    background-color="primary"
    style="max-width: 170px"
    :label="menuLabel"
    hide-details
    :loading="isActionInProgress"
    :readonly="isActionInProgress"
    @change="doMenuAction"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import contracts from '@/services/contracts'
import { baseUrl } from '@/utils/env'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    contract: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedMenuItem: '',
      isActionInProgress: false,
      menuItemsDefinitions: [
        {
          value: 'edit-contract',
          text: 'Edit',
          action: this.editContract,
        },
        {
          value: 'mark-contract-signed',
          text: 'Mark Contract as Signed',
          action: this.markContractSigned,
        },
        {
          value: 'view-contract-pdf',
          text: 'View PDF',
          action: this.viewPDF,
        },
        {
          value: 'client-view',
          text: 'Client View',
          action: this.clientView,
        },
        {
          value: 'close-contract',
          text: 'Close Contract',
          action: this.closeContract,
        },
        {
          value: 'enable-public-tracking',
          text: `${
            this.contract?.trackingUriMapId ? 'Update' : 'Enable'
          } Public Tracking`,
          action: this.enablePublicTracking,
        },
        {
          value: 'enable-rider-tracking',
          text: `${
            this.contract?.hasRiderTracking ? 'Update' : 'Enable'
          } Rider Tracking`,
          action: this.openRiderTrackingSidebar,
        },
      ],
      actionMap: {},
      componentMap: {},
      pdfBaseURL: baseUrl('pdf'),
    }
  },
  computed: {
    ...mapGetters('app', ['shouldShowTheDialog']),
    ...authComputed,
    menuLabel() {
      return this.isActionInProgress ? 'In Progress' : 'Actions'
    },
    menuItems() {
      const { getters = {} } = this.$store
      let menuItems = []
      menuItems.push(this.menuItemsDefinitions[2])
      menuItems.push(this.menuItemsDefinitions[5])

      if (
        !getters['systemParameters/contractRiderTrackingDisabled'] &&
        this.contract?.customer?.customerAccount?.hasRiderTracking
      ) {
        menuItems.push(this.menuItemsDefinitions[6])
      }

      if (this.isOpen) {
        menuItems.push(this.menuItemsDefinitions[0])
        menuItems.push(this.menuItemsDefinitions[3])
        if (!this.isSigned) {
          menuItems.push(this.menuItemsDefinitions[1])
        }
      }
      return menuItems
    },
    isOpen() {
      return this.contract.contractIsOpen
    },
    isSigned() {
      return this.contract.contractIsSigned
    },
    canOpenCharterUpQuote() {
      const roles = this.currentUserProfile?.roles || []
      const charterUpRole = roles.find(
        (r) => r.roleName === 'is_charterup_sales'
      )
      const charterUpPermissions = charterUpRole?.permissions || []
      return !!charterUpPermissions.find(
        (p) => p.permissionName === 'canSendCharterUPQuote'
      )
    },
  },
  watch: {
    menuItems() {
      this.refreshActionAndComponentMaps()
    },
  },
  mounted() {
    this.refreshActionAndComponentMaps()
  },
  methods: {
    refreshActionAndComponentMaps() {
      for (const item of this.menuItems) {
        this.actionMap[item.value] = item.action
        if (item.component) {
          this.componentMap[item.value] = item.component
        }
      }
    },
    async editContract() {
      this.$router.push({ name: `contracts.edit`, params: { mode: 'edit' } })
    },
    async markContractSigned() {
      const response = await contracts
        .markContractSigned(this.contract.contractId)
        .catch((e) => e.response)
      this.$store.dispatch('app/showAlert', {
        type: response.data.successful ? 'success' : 'error',
        message: response.data.message,
      })
    },
    async closeContract() {
      const response = await contracts
        .closeContract(this.contract.contractId)
        .catch((e) => e.response)
      this.$store.dispatch('app/showAlert', {
        type: response.data.successful ? 'success' : 'error',
        message: response.data.message,
      })
    },
    clientView() {
      window.open(
        `${this.contract.checkoutPage?.domain || ''}/ci/checkout/contract/${
          this.contract.hash
        }`,
        '_blank'
      )
    },
    enablePublicTracking() {
      const component = () => import('@/components/PublicTrackingSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Enable Public Tracking',
          contract: this.contract,
        },
        component,
      })
    },
    openRiderTrackingSidebar() {
      const component = () => import('@/components/RiderTrackingSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: `${
            this.contract?.hasRiderTracking ? 'Update' : 'Enable'
          } Rider Tracking`,
          contract: this.contract,
        },
        component,
      })
    },
    async updateRiderTracking() {
      const payload = {
        quoteId: this.contract.contractId,
        enabled: !this.contract.hasRiderTracking,
      }
      const response = await contracts
        .updateRiderTracking(payload)
        .catch((e) => e.response)
      this.$store.dispatch('app/showAlert', {
        type: response.data.successful ? 'success' : 'error',
        message: response.data.successful
          ? 'Rider Tracking Updated.'
          : response.data.message,
      })
      this.menuItemsDefinitions[6].text = `${
        !this.contract?.hasRiderTracking ? 'Disable' : 'Enable'
      } Rider Tracking`
    },
    async doMenuAction(menuKey) {
      if (this.isActionInProgress) {
        return
      }
      this.isActionInProgress = true
      const action = this.actionMap[menuKey]
      this.$nextTick(() => {
        this.selectedMenuItem = ''
      })

      await action()
      this.$emit('refresh')
      this.isActionInProgress = false
    },
    viewPDF() {
      window.open(
        `//${this.pdfBaseURL}/pdf/contract-quote-${this.contract.hash}.pdf`,
        '_blank'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
// FIXME: consider moving this to a global style: NaN;
// first will have to check and make sure that won't
// break the designs elsewhere.
.contract-action-menu ::v-deep {
  overflow: hidden;

  label:not(.v-label) {
    display: none;
  }

  label,
  .v-icon {
    color: $primary;
  }

  .v-input__control {
    min-height: 40px !important;
    > .v-input__slot {
      max-height: 40px !important;
      min-height: 40px !important;
    }
  }
}
</style>
