<template>
  <v-container fluid>
    <v-layout column sheet>
      <DataTable ref="table" v-bind.sync="tableProps">
        <template #actionDropDown>
          <v-menu>
            <template #activator="{ on }">
              <v-btn class="btn-secondaryaction" v-on="on">
                Actions
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile
                v-for="action in actionsList"
                :id="action.id"
                :key="`action-item-${action.id}`"
                :disabled="loading"
                @click.stop="action.action"
              >
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  :size="24"
                  color="primary"
                  width="2"
                  style="margin-right: 8px"
                />
                <v-list-tile-title>{{ action.name }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </template>
      </DataTable>
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import QuotesListDetail from '@/components/QuotesListDetail.vue'
import QuotesActionableColumn from '@/components/QuotesActionableColumn.vue'
import ContractQuotesTableActions from '@/components/ContractQuotesTableActions.vue'
import { DateTime } from 'luxon'
import * as logger from '@/utils/logger'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import quotesActionsWrapper from '@/components/ContractQuotesActionsWrapper'
import {
  calculatedValues,
  datePredefined,
  numericRangePredefined,
  textLike,
} from '@/utils/predefined'
import admin from '@/services/admin'
import { mapGetters } from 'vuex'

const wrappedActions = quotesActionsWrapper(ContractQuotesTableActions)

const quoteConversionStatusMap = [
  { text: 'Unconverted', value: 'false' },
  { text: 'Converted', value: 'true' },
]
const categories = [
  {
    _t_id: '1711fa30',
    text: 'Quote Status',
    prop: 'isConverted',
    values: deepClone(quoteConversionStatusMap),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    DataTable,
  },
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
    customerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      loading: false,
      categoryParentId: undefined,
      selectedCategory: undefined,
      itemsPerPage: 10,
      page: 1,
      tableProps: {
        enableExport: false,
        enableColumnConfig: false,
        enableSavedViews: false,
        enableStatusFilterToggle: true,
        tableId: 'customer_details_quotes_table_view',
        total: 0,
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        initialFilters: [],
        categories,
        detail: QuotesListDetail,
        detailKeyId: 'quoteId',
        isDetailed: false,
        addNewEnabled: false,
        enableSelectableRows: false,
        selectedRows: [],
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        calculatedValues,
        columns: [
          {
            _t_id: 'f4dfd342',
            prop: '/',
            text: 'Actions',
            component: wrappedActions,
            sort: false,
            filter: false,
            detail: false,
            type: 'actions',
          },
          {
            _t_id: 'f4dfd5e0',
            prop: 'quoteId',
            text: 'ID',
            component: QuotesActionableColumn,
            sort: true,
            filter: true,
            detail: false,
            type: 'number',
            displayType: 'clickable-link-new',
            filterType: 'eq',
          },
          {
            _t_id: 'b7bcc554',
            prop: 'isConverted',
            text: 'Status',
            computedText: (item) => (item ? 'Converted' : 'Unconverted'),
            sort: false,
            filter: false,
            defaultHidden: false,
          },
          {
            _t_id: 'f4dfd978',
            prop: 'createdOn',
            text: 'Created Date',
            computedText: (item) =>
              DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
            sort: true,
            filter: true,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
            defaultSort: true,
          },
          {
            _t_id: 'f4dfdd06',
            prop: 'convertedOn',
            text: 'Converted Date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
            sort: true,
            filter: true,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: '1a25db55',
            prop: 'pickupLocation',
            text: 'Pickup Location',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            filterProp: 'pickupLocation',
            sortProp: 'pickupLocation',
          },
          {
            _t_id: 'b7bcc3d8',
            prop: 'pickupDate',
            text: 'Start Date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
            sort: true,
            filter: true,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            defaultHidden: false,
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'contract_id_filter',
            filterType: 'eq',
            prop: 'contractId',
            type: 'number',
            filterPlaceholder: true,
          },
        ],
      },
      actionsList: [
        {
          id: 'full-convert-quotes',
          name: 'Full Convert Quote',
          action: () => {
            this.fullConvertQuotes()
          },
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    canCreateShuttleQuotes() {
      return this.$store.getters['auth/hasPermission']('canCreateShuttleQuotes')
    },
    ...mapGetters({
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
    }),
  },
  mounted() {
    this.actionsList.push({
      id: 'add-new-quote',
      name: 'Add New Quote',
      action: () => {
        this.pushNewQuote()
      },
    })
    if (this.isShuttleCreateQuoteEnabled && this.canCreateShuttleQuotes) {
      this.actionsList.push({
        id: 'add-new-route-quote',
        name: 'Add New Route Quote',
        action: () => {
          this.pushNewRouteQuote()
        },
      })
    }
    if (!this.isShuttleCreateQuoteEnabled) {
      this.tableProps.addNewEnabled = true
      this.tableProps.addNewHandler = () => {
        this.$router.push({
          name: 'quotes.add',
          query: { contractId: this.contract.contractId },
        })
      }
    }

    this.tableProps.columns.push(
      {
        _t_id: 'f4df7e64',
        prop: ['customer/bookings'],
        text: 'Customer Bookings Per Year',
        sort: true,
        filter: true,
        component: QuotesActionableColumn,
        type: 'number',
        action: 'CUSTOMER_DETAIL',
        detail: false,
        sortProp: 'customer/bookings',
        filterType: 'eq',
        childMethod: 'and',
        defaultHidden: true,
        predefined: deepClone(numericRangePredefined),
      },
      {
        _t_id: 'xb7bcc554',
        prop: 'isCharterUpQuote',
        text: 'Marketplace',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        filterType: 'eq',
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        defaultHidden: true,
        predefined: [
          {
            text: 'Marketplace',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            checkbox: true,
            value: 1,
            customEval: (activeFilter) => {
              if (!activeFilter.value) {
                activeFilter.value = true
              } else {
                activeFilter.value = undefined
              }
            },
          },
        ],
      }
    )

    this.tableProps.columns.push({
      _t_id: 'f4d9de64',
      prop: ['customer/organization'],
      text: 'Organization',
      sort: true,
      filter: true,
      component: QuotesActionableColumn,
      type: 'text',
      action: 'CUSTOMER_DETAIL',
      detail: false,
      displayType: 'action-item',
      sortProp: 'customer/organization',
      filterType: 'contains',
      childMethod: 'and',
      defaultHidden: true,
      predefined: textLike,
    })

    this.tableProps.columns.push({
      _t_id: 'b7bc93d8',
      prop: 'decisionDate',
      text: 'Decision Date',
      computedText: (item) =>
        item === null
          ? ''
          : DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
      sort: true,
      filter: true,
      type: 'date',
      method: 'and',
      childMethod: 'and',
      filterType: 'eq',
      defaultHidden: true,
      predefined: deepClone(datePredefined),
    })

    this.tableProps.columns.push({
      _t_id: 'b7bk93d8',
      prop: 'participatingInEmailCampaigns',
      text: 'Email Campaign Enabled',
      computedText: (item) => (item ? 'Enabled' : 'Disabled'),
      sort: true,
      filter: true,
      type: 'text',
      method: 'and',
      childMethod: 'and',
      filterType: 'eq',
      defaultHidden: true,
      customFilterTabDisplay: (val) => {
        return val ? 'Open' : 'Closed'
      },
      predefined: [
        {
          text: 'Email Campaign Enabled',
          controlType: 'default-repeat',
          refreshOnSelect: true,
          withValue: true,
          checkbox: true,
          value: 1,
          customEval: (activeFilter) => {
            if (!activeFilter.value) {
              activeFilter.value = true
            } else {
              activeFilter.value = undefined
            }
          },
        },
      ],
    })

    this.tableProps.initialFilters = [
      {
        column: this.tableProps.columns.find(
          (column) => column._t_id === 'contract_id_filter'
        ),
        value: this.contract?.contractId,
        hideOnFilterBar: true,
      },
    ]
  },
  methods: {
    pushNewQuote() {
      this.$router.push({
        name: 'quotes.add',

        query: { contractId: this.contract.contractId },
      })
    },
    pushNewRouteQuote() {
      this.$router.push({
        name: 'quotes.new',
        params: {
          mode: 'add',
        },
        query: { contractId: this.contract.contractId },
      })
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    handleDelete(targetRow) {
      this.tableProps.list = this.tableProps.list.filter(
        (row) => row.quoteId !== targetRow.quoteId
      )
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const { company } = this.currentUser
      const params = {
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        companyId: company.companyId,
        quotes: 'company',
      }
      this.tableProps.loading = true
      const quoteData = await this.$store
        .dispatch('quotes/quotesListView', params)
        .catch((error) => {
          logger.error(error)
          this.tableProps.loading = false

          return false
        })

      if (quoteData) {
        this.tableProps.perPage = this.itemsPerPage
        this.tableProps.currentPage = this.currentPage
        this.tableProps.list = quoteData.data.resultList
        this.tableProps.list.forEach((item) => {
          item.deleteMe = this.handleDelete.bind(this)
        })
        this.tableProps.total = quoteData.data.count
        this.tableProps.loading = false
      }
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    async fullConvertQuotes() {
      this.loading = true
      await admin.convertQuotesEnMasse({
        contractId: this.contract.contractId,
      })
      this.loading = false
      this.refresh(true)
      this.$store.dispatch('app/showAlert', {
        color: 'success',
        message: 'All quotes successfully converted.',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tag {
  cursor: pointer;
}
</style>
<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}
</style>
